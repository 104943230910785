import {notifyError} from './errorTracker/errrorTracker';

/**
 * Decode tourmaline id.
 * If the id is invalid, it returns empty string for all fields.
 * @param tourmalineId base64 encoded tourmaline id
 */
export const decodeId = (
  tourmalineId: string
): {
  docPath: string;
  collectionPath: string;
  docId: string;
  collectionName: string;
} => {
  // graphqlIdはbase64エンコードされた状態
  try {
    const decoded = window.atob(tourmalineId);
    const [collectionName, docPath] = decoded.split(':');

    if (!docPath || !collectionName) {
      throw new Error('Invalid tourmaline id: ' + tourmalineId);
    }

    const paths = docPath.split('/');

    const docId = paths[paths.length - 1];
    if (!docId) {
      throw new Error('Invalid tourmaline id: ' + tourmalineId);
    }
    const collectionPath = paths.slice(0, paths.length - 2).join('/');

    return {
      docPath,
      collectionPath,
      docId,
      collectionName,
    };
  } catch (e) {
    notifyError(
      new Error('Error while decoding tourmaline id: ' + tourmalineId, {
        cause: e instanceof Error ? e : undefined,
      })
    );
    return {
      docPath: '',
      collectionPath: '',
      docId: '',
      collectionName: '',
    };
  }
};

/**
 * Encode tourmaline id
 * @param collectionName collection name
 * @param args document path or document id
 * @example
 * - `encodeId("Organization", "customers", "xxx")`
 * - `encodeId("Project", "customers", "xxx", "projects", "yyy")`
 */
export const encodeId = (collectionName: string, ...args: string[]): string => {
  // argsは'/'区切りのドキュメントパスでも、コレクションパス＋ドキュメントIDでもOK
  return window.btoa(collectionName + ':' + args.join('/')).replaceAll('"', '');
};

/**
 * Encoded id for default url group
 * ('URLGroup:/default')
 */
export const DEFAULT_URL_GROUP_ID = 'VVJMR3JvdXA6L2RlZmF1bHQ=';
/**
 * Encoded id for default query group
 * ('QueryGroup:/default')
 */
export const DEFAULT_QUERY_GROUP_ID = 'UXVlcnlHcm91cDovZGVmYXVsdA==';
/**
 * Encoded id for anonymous query group
 * ('QueryGroup:/anonymous')
 */
export const ANONYMOUS_QUERY_GROUP_ID = 'UXVlcnlHcm91cDovYW5vbnltb3Vz';
