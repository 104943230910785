import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M12.5 22a9.671 9.671 0 0 1-3.875-.788 10.126 10.126 0 0 1-3.187-2.15 10.125 10.125 0 0 1-2.15-3.187A9.67 9.67 0 0 1 2.5 12a9.64 9.64 0 0 1 .788-3.887 10.164 10.164 0 0 1 2.15-3.175 10.14 10.14 0 0 1 3.187-2.151A9.681 9.681 0 0 1 12.5 2a9.65 9.65 0 0 1 3.887.787 10.178 10.178 0 0 1 3.175 2.151 10.164 10.164 0 0 1 2.15 3.175A9.64 9.64 0 0 1 22.5 12a9.671 9.671 0 0 1-.788 3.875 10.125 10.125 0 0 1-2.15 3.187 10.164 10.164 0 0 1-3.175 2.15A9.64 9.64 0 0 1 12.5 22Zm0-2.05c.433-.6.808-1.225 1.125-1.875.317-.65.575-1.342.775-2.075h-3.8c.2.733.458 1.425.775 2.075.317.65.692 1.275 1.125 1.875Zm-2.6-.4c-.3-.55-.562-1.121-.787-1.713A14.607 14.607 0 0 1 8.55 16H5.6a8.304 8.304 0 0 0 1.812 2.175A7.2 7.2 0 0 0 9.9 19.55Zm5.2 0a7.19 7.19 0 0 0 2.487-1.375A8.295 8.295 0 0 0 19.4 16h-2.95c-.15.633-.337 1.246-.562 1.837a13.89 13.89 0 0 1-.788 1.713ZM4.75 14h3.4A13.365 13.365 0 0 1 8 12a13.365 13.365 0 0 1 .15-2h-3.4a8.012 8.012 0 0 0-.25 2 8.012 8.012 0 0 0 .25 2Zm5.4 0h4.7a13.35 13.35 0 0 0 .15-2 13.35 13.35 0 0 0-.15-2h-4.7a13.6 13.6 0 0 0-.15 2 13.033 13.033 0 0 0 .15 2Zm6.7 0h3.4a8.018 8.018 0 0 0 .25-2 8.018 8.018 0 0 0-.25-2h-3.4a13.6 13.6 0 0 1 .15 2 13.033 13.033 0 0 1-.15 2Zm-.4-6h2.95a8.294 8.294 0 0 0-1.813-2.175A7.19 7.19 0 0 0 15.1 4.45c.3.55.563 1.12.788 1.712.225.592.412 1.205.562 1.838ZM10.6 8h3.8c-.2-.733-.458-1.425-.775-2.075A12.701 12.701 0 0 0 12.5 4.05c-.433.6-.808 1.225-1.125 1.875-.317.65-.575 1.342-.775 2.075Zm-5 0h2.95c.15-.633.338-1.246.563-1.838C9.338 5.571 9.6 5 9.9 4.45a7.2 7.2 0 0 0-2.488 1.375A8.303 8.303 0 0 0 5.6 8Z"
    />
  </svg>
);
export default SvgIconLanguage;
