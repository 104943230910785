import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {IconSearchAnalytics} from 'common/icons';
import {GettingStartedTour, SideNavigationOrgFragment} from 'lib/gql/generated';
import {useCallback, useState} from 'react';
import {MdArrowRight, MdStackedLineChart} from 'react-icons/md';
import NextLink from 'next/link';
import {pagesPath} from 'lib/$path';
import {decodeId} from 'common/convertId';
import {useQueryParamOrgId} from 'features/org/store';
import {useRouter} from 'next/router';
import {DEFAULT_VIEW_ID} from 'features/search_analytics/constants';
import {useAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {useTranslation} from 'next-i18next';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';
import {useRunningTour} from 'features/getting_started/tour/store/toursStore';

type Props = {
  projects: SideNavigationOrgFragment['searchAnalyticsProjects'];
};

const isOpenAtom = atomWithStorage('searchAnalyticsProjects', true);
const SHOW_SIZE = 5;

export const SearchAnalyticsProjects = ({projects}: Props) => {
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const [isHover, setIsHover] = useState(false);
  const {orgDatabaseId} = useQueryParamOrgId();
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const saProjects = projects.filter(
    p => p.__typename === 'SearchAnalyticsProject'
  );
  const count = saProjects.length;

  if (!orgDatabaseId) return null;

  return (
    <Stack spacing="2px">
      <Button
        size="sm"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={isOpen ? onClose : onOpen}
        leftIcon={
          <Box w="18px" h="18px">
            {isHover ? (
              <MdArrowRight
                size="100%"
                style={{
                  transition: 'transform 0.2s',
                  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            ) : (
              <IconSearchAnalytics
                viewBox="2 2 20 20"
                width="100%"
                height="100%"
              />
            )}
          </Box>
        }
        rightIcon={
          isOpen ? undefined : (
            <Badge
              color="amethyst.prior"
              background="amethyst.extraLight"
              fontSize="memo1"
              px="6px"
              py="2px"
              rounded="lg"
            >
              {count}
            </Badge>
          )
        }
        variant="ghost"
        fontWeight="bold"
      >
        {t('searchAnalyticsProjects')}
      </Button>
      {isOpen &&
        saProjects.map((p, i) =>
          p.__typename === 'SearchAnalyticsProject' ? (
            <SearchAnalyticsProject key={p.id} project={p} showTour={i === 0} />
          ) : null
        )}
      {isOpen && saProjects.length === 0 && (
        <Box p="2" color="color.hambleA11y" fontSize="text2">
          {t('noSearchAnalyticsProjects')}
        </Box>
      )}
    </Stack>
  );
};

const SearchAnalyticsProject = ({
  project,
  showTour = false,
}: {
  project: SideNavigationOrgFragment['searchAnalyticsProjects'][number];
  showTour?: boolean;
}) => {
  const {orgDatabaseId} = useQueryParamOrgId();
  const [isHover, setIsHover] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure({
    defaultIsOpen: false,
  });
  const router = useRouter();
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const {progress, Tooltip} = useTourProgress(showTour);

  if (!orgDatabaseId) return null;
  if (project.__typename !== 'SearchAnalyticsProject') return null;

  const pushToProject = () =>
    router.push(
      pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(decodeId(project.id).docId)
        .search_analytics.$url()
    );

  return (
    <Stack spacing="0">
      <Tooltip>
        <Button
          size="sm"
          as="div" // prevent nesting interactive elements
          tabIndex={0}
          role="button"
          key={project.id}
          variant="ghost"
          justifyContent="flex-start"
          w="100%"
          aria-current={
            router.pathname ===
              pagesPath.orgs
                ._orgId(orgDatabaseId)
                .projects._projectId(decodeId(project.id).docId)
                .search_analytics.$url().pathname &&
            router.query.projectId === decodeId(project.id).docId
              ? true
              : undefined
          }
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onKeyDown={e => {
            // space: toggle open/close
            if (e.key === ' ') {
              e.preventDefault();
              isOpen ? onClose() : onOpen();
            }
            if (e.key === 'Enter') {
              e.preventDefault();
              onOpen();
              pushToProject();
            }
          }}
          onClick={e => {
            e.preventDefault();
            onOpen();
            pushToProject();
            progress();
          }}
          leftIcon={
            <Box
              w="18px"
              h="18px"
              rounded="sm"
              backgroundColor={isHover ? '' : project.color}
              _hover={{
                background: '#D9D9D9',
              }}
              onClick={e => {
                // open/close on click icon
                e.preventDefault();
                e.stopPropagation();
                isOpen ? onClose() : onOpen();
              }}
            >
              {isHover ? (
                <MdArrowRight
                  size="100%"
                  style={{
                    transition: 'transform 0.2s',
                    transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />
              ) : null}
            </Box>
          }
        >
          {project.name}
        </Button>
      </Tooltip>
      <HStack spacing="2px">
        <Divider orientation="vertical" ml="20px" />
        <Stack spacing="0" overflow="hidden" flex="1" p="2px" m="-2px">
          {isOpen && !project.searchAnalytics?.latestDataDate && (
            <Box p="2" color="color.hambleA11y" fontSize="text2">
              {t('noData')}
            </Box>
          )}
          {isOpen && project.searchAnalytics?.latestDataDate && (
            <Views
              project={project}
              orgDatabaseId={orgDatabaseId}
              projectDatabaseId={decodeId(project.id).docId}
            />
          )}
        </Stack>
      </HStack>
    </Stack>
  );
};

const Views = ({
  project,
  orgDatabaseId,
  projectDatabaseId,
}: {
  project: SideNavigationOrgFragment['searchAnalyticsProjects'][number];
  orgDatabaseId: string;
  projectDatabaseId: string;
}) => {
  const [showAll, setShowAll] = useState(false);
  const views =
    project.__typename === 'SearchAnalyticsProject'
      ? project.searchAnalytics?.views ?? []
      : [];
  const shownViews = showAll ? views : views.slice(0, SHOW_SIZE - 1); // -1 for all button
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const router = useRouter();

  if (views.length === 0) return null;

  return (
    <>
      {/* All */}
      <NextLink
        legacyBehavior
        passHref
        href={pagesPath.orgs
          ._orgId(orgDatabaseId)
          .projects._projectId(projectDatabaseId)
          .search_analytics._viewId(DEFAULT_VIEW_ID)
          .$url()}
      >
        <Button
          as="a"
          size="sm"
          variant="ghost"
          justifyContent="flex-start"
          w="100%"
          leftIcon={
            <Box w="18px" h="18px">
              <MdStackedLineChart size="18px" />
            </Box>
          }
          aria-current={
            router.pathname ===
              pagesPath.orgs
                ._orgId(orgDatabaseId)
                .projects._projectId(projectDatabaseId)
                .search_analytics._viewId(DEFAULT_VIEW_ID)
                .$url().pathname &&
            router.query.projectId === projectDatabaseId &&
            router.query.viewId === DEFAULT_VIEW_ID
          }
        >
          <Box overflow="hidden">{t('(all)')}</Box>
        </Button>
      </NextLink>

      {/* Views */}
      {shownViews.map(v => (
        <NextLink
          key={v.id}
          legacyBehavior
          passHref
          href={pagesPath.orgs
            ._orgId(orgDatabaseId)
            .projects._projectId(projectDatabaseId)
            .search_analytics._viewId(decodeId(v.id).docId)
            .$url()}
        >
          <Button
            as="a"
            size="sm"
            variant="ghost"
            justifyContent="flex-start"
            w="100%"
            leftIcon={
              <Box w="18px" h="18px">
                <MdStackedLineChart size="18px" />
              </Box>
            }
            aria-current={
              router.pathname ===
                pagesPath.orgs
                  ._orgId(orgDatabaseId)
                  .projects._projectId(projectDatabaseId)
                  .search_analytics._viewId(decodeId(v.id).docId)
                  .$url().pathname &&
              router.query.projectId === projectDatabaseId &&
              router.query.viewId === decodeId(v.id).docId
            }
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {v.name}
            </Box>
          </Button>
        </NextLink>
      ))}

      {/* Show all button */}
      {views.length > SHOW_SIZE && !showAll && (
        <Button
          size="sm"
          variant="ghost"
          onClick={() => setShowAll(true)}
          justifyContent="flex-start"
          color="color.hambleA11y"
        >
          {t('showAll')}
        </Button>
      )}
    </>
  );
};

const useTourProgress = (showTour: boolean) => {
  const runningTour = useRunningTour();
  const {progress: progressScIntegration} = useTour({
    tour: GettingStartedTour.SaSearchConsoleIntegration,
    step: 'clickSearchAnalyticsProject',
  });
  const {progress: progressCreateView} = useTour({
    tour: GettingStartedTour.SaCreateView,
    step: 'clickSearchAnalyticsProject',
  });
  const {progress: progressCreateNavigationalQueryGroup} = useTour({
    tour: GettingStartedTour.SaCreateNavigationalQueryGroup,
    step: 'clickSearchAnalyticsProject',
  });
  const {progress: progressCreateUrlGroup} = useTour({
    tour: GettingStartedTour.SaCreateUrlGroup,
    step: 'clickSearchAnalyticsProject',
  });
  const handleProgress = () => {
    if (showTour) {
      progressScIntegration();
      progressCreateView();
      progressCreateNavigationalQueryGroup();
      progressCreateUrlGroup();
    }
  };

  const ScTourTooltip = useCallback(
    (props: {children: React.ReactNode}) => {
      if (!showTour) return <>{props.children}</>;
      if (
        runningTour !== GettingStartedTour.SaSearchConsoleIntegration &&
        runningTour !== GettingStartedTour.SaCreateView &&
        runningTour !== GettingStartedTour.SaCreateNavigationalQueryGroup &&
        runningTour !== GettingStartedTour.SaCreateUrlGroup
      )
        return <>{props.children}</>;

      return (
        <TourTooltip tour={runningTour} step="clickSearchAnalyticsProject">
          {props.children}
        </TourTooltip>
      );
    },
    [runningTour, showTour]
  );

  return {progress: handleProgress, Tooltip: ScTourTooltip};
};
