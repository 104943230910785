import {
  Box,
  Button,
  Flex,
  Stack,
  Tooltip,
  TooltipProps,
  chakra,
} from '@chakra-ui/react';
import {useAbortTour, useRunningTour, useTourIndex} from '../store/toursStore';
import {useTranslation} from 'next-i18next';
import {GettingStartedTour} from 'lib/gql/generated';
import {StepType, Steps, Translations} from '../tours';
import {ComponentPropsWithoutRef, forwardRef, useEffect, useRef} from 'react';

type Props<T extends GettingStartedTour> = {
  children: React.ReactNode;
  tour: T | null;
  step: StepType<T>;
  ref?: React.RefObject<HTMLElement>;
  spanSxProps?: ComponentPropsWithoutRef<typeof chakra.span>['sx'];
} & Omit<TooltipProps, 'label' | 'isOpen'>;

// forwardRef does not support generic type, so use any and cast it to T later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TourTooltip = forwardRef<HTMLElement, Props<any>>(
  ({children: c, tour: to, step: s, spanSxProps, ...tooltipProps}, ref) => {
    const children: React.ReactNode = c;
    const tour: GettingStartedTour | null = to;
    const step: StepType<GettingStartedTour> = s;

    const {t} = useTranslation('tour');

    const runningTour = useRunningTour();
    const run = runningTour === tour;
    const [currentIndex] = useTourIndex();
    const current = tour ? Steps[tour][currentIndex] : null;
    const isOpen = run && current === step;

    const translations = tour ? Translations[tour] : null;

    const label = translations
      ? (t(translations[step as keyof typeof translations]) as string)
      : null;

    const childRef = useRef<HTMLElement>(null);
    useEffect(() => {
      if (isOpen) {
        childRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }, [isOpen]);

    if (!tour) return <>{children}</>;
    if (!label) return <>{children}</>;

    return (
      <TourTooltipInner
        label={label}
        isOpen={isOpen}
        ref={ref}
        {...tooltipProps}
      >
        <chakra.span ref={childRef} sx={spanSxProps}>
          {children}
        </chakra.span>
      </TourTooltipInner>
    );
  }
) as (<T extends GettingStartedTour>(
  props: Props<T> & React.RefAttributes<HTMLElement>
) => JSX.Element) & {
  displayName: string;
};
TourTooltip.displayName = 'TourTooltip';

export const TourTooltipInner = forwardRef<HTMLElement, TooltipProps>(
  (props, ref) => {
    const abort = useAbortTour();
    const {t} = useTranslation('tour');

    return (
      <Tooltip
        ref={ref}
        p="small"
        hasArrow
        {...props}
        pointerEvents="all"
        label={
          <Stack>
            <Box>{props.label}</Box>
            <Flex justifyContent="flex-end">
              <Button
                variant="link"
                fontSize="text2"
                color="white"
                onClick={abort}
              >
                {t('tour.abort')}
              </Button>
            </Flex>
          </Stack>
        }
      >
        {props.children}
      </Tooltip>
    );
  }
);
TourTooltipInner.displayName = 'TourTooltipInner';
