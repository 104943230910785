import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const amethyst = defineStyle({
  fontSize: '13px',
  color: 'color.regular',
  border: '1px solid',
  borderColor: '#C4C4C4',
  padding: '11px 11px',
  _hover: {
    borderColor: '#E5E5E5',
  },
  _focusVisible: {
    padding: '10px 10px',
    borderWidth: '2px',
    borderColor: 'amethyst.regular',
  },
  _invalid: {
    border: '2px solid #ED4337',
  },
  _placeholder: {
    color: 'color.hambleA11y',
  },
});

export const textareaTheme = defineStyleConfig({
  variants: {amethyst},
});
