import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.saCreateUrlGroup'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickSearchAnalyticsProject',
  'clickQueryUrlGroup',
  'clickAdd',
  'enterUrlGroupName',
  'enterUrl',
  'clickReviewButton',
  'confirmAndCreateUrlGroup',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.saCreateUrlGroup.openMenu',
  clickSearchAnalyticsProject:
    'tour.saCreateUrlGroup.clickSearchAnalyticsProject',
  clickQueryUrlGroup: 'tour.saCreateUrlGroup.clickQueryUrlGroup',
  clickAdd: 'tour.saCreateUrlGroup.clickAdd',
  enterUrlGroupName: 'tour.saCreateUrlGroup.enterUrlGroupName',
  enterUrl: 'tour.saCreateUrlGroup.enterUrl',
  clickReviewButton: 'tour.saCreateUrlGroup.clickReviewButton',
  confirmAndCreateUrlGroup: 'tour.saCreateUrlGroup.confirmAndCreateUrlGroup',
};
