import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {relayStylePagination} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import {TOURMALINE_TOKEN_LOCAL_STORAGE_KEY} from 'common/storage';
import {ReactNode} from 'react';
import {TOURMALINE_ENDPOINT} from './constatns';
import {createUploadLink} from 'apollo-upload-client';
import {LOCALE_COOKIE_NAME} from 'common/storage';
import {notifyError} from 'common/errorTracker/errrorTracker';
import dayjs from 'dayjs';
import {Env} from 'env/env';
import {withScalars} from 'apollo-link-scalars';
import {IntrospectionQuery, buildClientSchema} from 'graphql';
import introspactionResult from 'lib/gql/graphql.schema.json';
import {DateTimeResolver} from 'graphql-scalars';

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem(TOURMALINE_TOKEN_LOCAL_STORAGE_KEY);
  // return the headers to the context so httpLink can read them

  const cookies = document.cookie.split(';');
  const localeCookie = cookies.find(cookie =>
    cookie.includes(LOCALE_COOKIE_NAME)
  );
  const lang = localeCookie?.split('=')?.[1] || 'en';

  if (Env.ENV === 'demo') {
    token = 'demo'; // demo mode
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Accept-Language': lang, // 'ja' or 'en'
    },
  };
});

const uploadLink = createUploadLink({
  uri: TOURMALINE_ENDPOINT,
});

const errorLink = onError(({graphQLErrors}) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      // NOTE: graphql error may not be instance of Error
      if (err instanceof Error) {
        notifyError(err);
      } else {
        notifyError(new Error(JSON.stringify(err)));
      }
    }
  }
});

/**
 * @deprecated
 * Converting date to UTC string in this link is not a good idea.
 * It's better to convert date to UTC string in the client code.
 */
const utcDateRequestLink = new ApolloLink((operation, forward) => {
  /**
   * FIXME: Don't depend on operation name.
   */
  const operationName = (
    operation.query.definitions[0] as {
      name?: {value: string};
    }
  )?.name?.value;
  if (
    operationName === 'SearchAnalyticsViewAggregatedData' ||
    operationName === 'SearchAnalyticsRawData' ||
    operationName === 'SearchAnalyticsProject'
  ) {
    const variables = operation.variables;
    const dateRange = variables?.dateRange;
    const start = dateRange?.start;
    const end = dateRange?.end;
    if (start) {
      variables.dateRange.start = dayjs(start).format(
        'YYYY-MM-DDT00:00:00.000[Z]'
      );
    }
    if (end) {
      variables.dateRange.end = dayjs(end).format('YYYY-MM-DDT00:00:00.000[Z]');
    }
    const comparingDateRange = variables?.comparingDateRange;
    const comparingStart = comparingDateRange?.start;
    const comparingEnd = comparingDateRange?.end;
    if (comparingStart) {
      variables.comparingDateRange.start = dayjs(comparingStart).format(
        'YYYY-MM-DDT00:00:00.000[Z]'
      );
    }
    if (comparingEnd) {
      variables.comparingDateRange.end = dayjs(comparingEnd).format(
        'YYYY-MM-DDT00:00:00.000[Z]'
      );
    }
  }
  return forward(operation);
});

const customScalarLink = withScalars({
  schema: buildClientSchema(
    introspactionResult as unknown as IntrospectionQuery
  ),
  typesMap: {Date: DateTimeResolver, Time: DateTimeResolver},
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          notifications: relayStylePagination(),
        },
      },
      SearchAnalytics: {
        fields: {
          rawData: relayStylePagination(),
        },
        merge: true,
      },
    },
  }),
  link: ApolloLink.from([
    errorLink,
    authLink,
    customScalarLink,
    utcDateRequestLink,
    uploadLink,
  ]),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

export const ApiClientProvider = ({children}: {children: ReactNode}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev';

if (process.env.NODE_ENV !== 'production') {
  loadErrorMessages();
  loadDevMessages();
}
