import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconSearchAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M19.855 17.82c.69-.4 1.21-1.31 1.2-2.11V8.14c0-.8-.61-1.69-1.3-2.09l-6.512-3.73c-.68-.39-1.75-.39-2.43 0L4.28 6.04C3.581 6.44 3.01 7.2 3 8l-.019 7.72c0 .79.52 1.72 1.21 2.11l6.632 3.85c.33.19.76.3 1.21.3.45 0 .89-.11 1.22-.3l6.602-3.86ZM4.89 16.61l6.632 3.85c.26.15.76.15 1.02 0l6.602-3.86c.229-.133.45-.49.5-.78a.67.67 0 0 0 .01-.11h-.01l.01-7.57c0-.31-.34-.73-.6-.88l-6.512-3.73c-.12-.07-.32-.11-.52-.11s-.4.04-.52.11L4.97 7.25c-.26.15-.59.6-.59.9v7.57c0 .3.25.74.51.89Zm11.713.62.8-.8-2.66-2.66c.3-.39.54-.82.71-1.28.17-.46.25-.96.25-1.49 0-1.26-.44-2.33-1.32-3.21-.88-.88-1.95-1.32-3.211-1.32-1.26 0-2.33.44-3.21 1.32-.881.88-1.321 1.95-1.321 3.21s.44 2.33 1.32 3.21c.88.88 1.95 1.32 3.21 1.32.531 0 1.021-.08 1.491-.25.47-.17.89-.41 1.28-.71l2.661 2.66Zm-3.03-3.82c-.66.66-1.46.99-2.401.99-.94 0-1.74-.33-2.4-.99-.66-.66-.99-1.46-.99-2.4s.33-1.74.99-2.4a3.27 3.27 0 0 1 2.4-.99c.94 0 1.74.33 2.4.99.66.66.99 1.46.99 2.4s-.33 1.74-.99 2.4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconSearchAnalytics;
