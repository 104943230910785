import {Portal as ChakraPortal, useModalContext} from '@chakra-ui/react';
import {ErrorBoundary} from 'react-error-boundary';

/**
 * Wrapper around Chakra's Portal component.
 * Uses modal context to render the portal inside the modal dialog.
 */
export const Portal = ({children}: {children: React.ReactNode}) => {
  return (
    <ErrorBoundary fallback={<ChakraPortal>{children}</ChakraPortal>}>
      <PortalInner>{children}</PortalInner>
    </ErrorBoundary>
  );
};

const PortalInner = ({children}: {children: React.ReactNode}) => {
  const modalContext = useModalContext();
  return (
    <ChakraPortal containerRef={modalContext?.dialogRef}>
      {children}
    </ChakraPortal>
  );
};
