import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Divider,
  ModalBody,
  Box,
  LayoutProps,
  Button,
} from '@chakra-ui/react';
import {ReactNode, useEffect, useRef} from 'react';
import {useTranslation} from 'next-i18next';
import {MdArrowBackIos} from 'react-icons/md';
import {DemoAlert} from './DemoAlert';

export type InputConfirmMode = 'input' | 'confirm';
export type InputConfirmCompleteMode = 'input' | 'confirm' | 'complete';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  title: string;
  titleHelp?: ReactNode;
  width?: LayoutProps['maxW'];
  children: ReactNode;
  closeOnOverlayClick?: boolean;
  height?: LayoutProps['height'];
  h?: LayoutProps['height'];
  maxH?: LayoutProps['maxH'];
  resetScrollKey?: string;
  disableCloseButton?: boolean;
};

export const Dialog = ({
  isOpen,
  onClose,
  onBack,
  title,
  titleHelp,
  width = '860px',
  children,
  closeOnOverlayClick = true,
  height,
  h,
  maxH,
  resetScrollKey,
  disableCloseButton,
}: Props) => {
  const {t} = useTranslation();

  const bodyRef = useRef<HTMLDivElement>(null);
  const prevResetScrollKey = useRef<string | undefined>();
  useEffect(() => {
    if (resetScrollKey && prevResetScrollKey.current !== resetScrollKey) {
      prevResetScrollKey.current = resetScrollKey;
      bodyRef.current?.scrollTo(0, 0);
    }
  }, [resetScrollKey]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent maxW={width}>
        <ModalHeader>
          {title}
          {titleHelp}
          <ModalCloseButton
            aria-label={t('general.閉じる')}
            isDisabled={disableCloseButton}
          />
          <Divider mt="8px" borderWidth="2px" />
        </ModalHeader>
        <ModalBody>
          <DemoAlert />
          <Box
            mx={{
              base: 0,
              md: '20px',
            }}
            my="25px"
            height={height}
            h={h}
            maxH={maxH}
            overflowY="auto"
            overflowX="hidden"
            ref={bodyRef}
          >
            {children}
          </Box>
          {onBack && (
            <Button
              variant="ghost"
              onClick={onBack}
              leftIcon={<MdArrowBackIos size="18px" />}
            >
              {t('general.戻る')}
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
