import {
  Box,
  HStack,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  IconButton,
  SimpleGrid,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import {Portal} from 'common/Portal';
import {GoodColors} from 'common/color/color';
import {useTranslation} from 'next-i18next';
import {MdOutlineEdit} from 'react-icons/md';

export const ColorPicker = ({
  color,
  onChange,
  onlyColor: onlyColor = false,
  errorMessage,
}: {
  color: string;
  onChange?: (color: string) => void;
  onlyColor?: boolean;
  errorMessage?: string | null;
}) => {
  const {t} = useTranslation();
  return (
    <HStack gap={2}>
      <Box>
        <Popover>
          <PopoverTrigger>
            {onlyColor ? (
              <IconButton
                aria-label={t('general.カラーを選択')}
                variant="ghost"
                borderColor={errorMessage ? 'color.attention' : undefined}
                borderWidth={errorMessage ? 2 : undefined}
                backgroundColor={color}
                _hover={{
                  backgroundColor: color,
                  opacity: 0.8,
                }}
                transition="opacity 0.1s ease"
                w="28px"
                h="28px"
                minW="28px"
                minH="28px"
              />
            ) : (
              <Button
                variant="ghost"
                leftIcon={
                  <Box w="24px" h="24px" rounded="sm" bgColor={color} />
                }
                rightIcon={<MdOutlineEdit size={26} />}
                aria-label={t('general.カラーを選択')}
                borderColor={errorMessage ? 'color.attention' : undefined}
                borderWidth={errorMessage ? 1 : undefined}
              />
            )}
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              boxShadow="0px 2px 5px rgba(0, 0, 0, 0.25)"
              rounded="none"
              border="none"
              p="10px"
              w="260px"
            >
              <PopoverCloseButton />
              <PopoverBody mt={2}>
                <Stack spacing="regular">
                  <SimpleGrid columns={4} gap="small">
                    {GoodColors.map(c => (
                      <Box
                        as="button"
                        type="button"
                        key={c}
                        backgroundColor={c}
                        width={10}
                        height={10}
                        cursor="pointer"
                        aria-label={t('general.{{color}} を選択', {color: c})}
                        onClick={() => onChange?.(c)}
                        rounded="sm"
                        position="relative"
                        _hover={{
                          opacity: 0.8,
                        }}
                        transition="opacity 0.1s ease"
                        _focusVisible={{
                          outline: 'none',
                          boxShadow: 'outline',
                        }}
                        // selected status
                        data-selected={c === color ? '' : undefined}
                        _after={{
                          content: '""',
                          display: 'block',
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          rounded: 'full',
                          backgroundColor: 'white',
                          transform: 'scale(0)',
                        }}
                        sx={{
                          '&[data-selected]': {
                            boxShadow: 'lg',
                            _focusVisible: {
                              boxShadow: 'outline',
                            },
                            '&:after': {
                              transform: 'scale(0.4)',
                              transition: 'transform 0.1s ease',
                            },
                          },
                        }}
                        onKeyDown={e => {
                          // move focus to the input when the user presses left, right, up, or down
                          if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
                            if (e.currentTarget.previousElementSibling) {
                              const prev = e.currentTarget
                                .previousElementSibling as HTMLElement;
                              prev.focus();
                            }
                          } else if (
                            e.key === 'ArrowRight' ||
                            e.key === 'ArrowDown'
                          ) {
                            if (e.currentTarget.nextElementSibling) {
                              const next = e.currentTarget
                                .nextElementSibling as HTMLElement;
                              next.focus();
                            }
                          }
                        }}
                      />
                    ))}
                  </SimpleGrid>
                  <FormControl isInvalid={!!errorMessage}>
                    <InputGroup variant="amethyst">
                      <InputLeftAddon>#</InputLeftAddon>
                      <Input
                        placeholder="4299e1"
                        value={color.split('#')[1] ?? ''}
                        onChange={e => onChange?.('#' + e.target.value)}
                        maxLength={6}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
    </HStack>
  );
};
