import {decodeId} from 'common/convertId';
import {useToast} from 'common/toast/useToast';
import {pagesPath} from 'lib/$path';
import {
  OrganizationsDocument,
  useAddOrganizationToContractMutation,
  useCreateOrganizationMutation,
  ViewerDocument,
} from 'lib/gql/generated';
import {useRouter} from 'next/router';
import {ChangeEvent, useCallback, useState} from 'react';
import {orgNameSchema} from './validation';
import {useOrg} from 'common/useOrg';
import {notifyError} from 'common/errorTracker/errrorTracker';

export const useCreateOrganization = ({onClose}: {onClose: () => void}) => {
  const [shouldValidate, setShouldValidate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [name, setName] = useState('');
  const nameErrorResult = orgNameSchema.safeParse(name);
  const isError = !nameErrorResult.success;
  const errorMessage =
    isError && shouldValidate ? nameErrorResult.error.errors[0]?.message : null;
  const toast = useToast();
  const router = useRouter();
  const [create, {loading}] = useCreateOrganizationMutation({
    variables: {
      input: {
        name,
      },
    },

    onError: e => {
      toast({
        resource: 'organization',
        operation: 'create',
        error: e,
      });
    },
  });

  const {currentOrg} = useOrg();
  const contractId = currentOrg?.contract?.id;
  const [addOrgToContract, {loading: loading2}] =
    useAddOrganizationToContractMutation({
      refetchQueries: [OrganizationsDocument, ViewerDocument],
      onError: e => {
        toast({
          resource: 'organization',
          operation: 'create',
          error: e,
        });
      },
    });

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);
  const handleClose = useCallback(() => {
    onClose();
    setName('');
    setShouldValidate(false);
  }, [onClose]);

  const handleGoToConfirm = useCallback(() => {
    setShouldValidate(true);
    if (isError) {
      return;
    }

    setIsConfirm(true);
  }, [isError]);

  const handleBackToForm = useCallback(() => {
    setIsConfirm(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!contractId) {
      notifyError(new Error('Contract id is not found'));
      return;
    }

    const {data} = await create();

    if (!data?.createOrganization?.id) {
      notifyError(new Error('Organization id is not found'));
      return;
    }
    await addOrgToContract({
      variables: {
        input: {
          contractID: contractId,
          organizationID: data?.createOrganization?.id,
        },
      },
    });

    toast({
      resource: 'organization',
      operation: 'create',
    });
    setIsConfirm(false);
    setName('');
    onClose();
    const databaseId = decodeId(data.createOrganization.id).docId;
    router.push(pagesPath.orgs._orgId(databaseId).$url());

    setShouldValidate(false);
  }, [contractId, create, addOrgToContract, toast, onClose, router]);

  return {
    name,
    errorMessage,
    isConfirm,
    loading: loading || loading2,
    handleNameChange,
    handleClose,
    handleGoToConfirm,
    handleBackToForm,
    handleSubmit,
  };
};
