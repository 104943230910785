import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconJobNoProblem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#565656" clipPath="url(#icon_-_job_no_problem_svg__a)">
      <path d="M7.46 20.69c1.09.56 2.27.91 3.54 1.04V19.69c-1.99-.25-3.65-1.12-4.99-2.61C4.67 15.59 4 13.82 4 11.77c0-1.26.27-2.44.81-3.51A8.042 8.042 0 0 1 7 5.55v2.72h2v-6H3v2h2.4c-1.05.92-1.88 2.02-2.49 3.3C2.3 8.85 2 10.25 2 11.77c0 1.3.23 2.53.7 3.68.46 1.15 1.1 2.17 1.91 3.06.81.9 1.76 1.62 2.85 2.18ZM15 15.28v5.99h6v-2h-2.4c1.05-.92 1.88-2.02 2.49-3.3.61-1.28.91-2.68.91-4.2 0-2.62-.87-4.87-2.6-6.75s-3.87-2.95-6.4-3.2v2.04c1.99.25 3.65 1.12 4.99 2.61C19.33 7.96 20 9.73 20 11.78c0 1.27-.27 2.43-.81 3.5A8.314 8.314 0 0 1 17 18.01v-2.73h-2Z" />
      <path d="m10.57 16.38-4.22-4.25 1.4-1.4 2.82 2.82 5.68-5.68 1.4 1.43-7.07 7.07-.01.01Z" />
    </g>
    <defs>
      <clipPath id="icon_-_job_no_problem_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconJobNoProblem;
