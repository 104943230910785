import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#5F5F5F"
      d="M17.758 7h-2v4h-4v2h4v4h2v-4h4v-2h-4V7Zm-1-5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z"
    />
  </svg>
);
export default SvgIconAdd;
