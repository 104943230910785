import {IconButton, Tooltip} from '@chakra-ui/react';
import {useSideNav} from './store';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {forwardRef, useState} from 'react';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {GettingStartedTour} from 'lib/gql/generated';
import {MdClose, MdMenu, MdMenuOpen} from 'react-icons/md';

type Props = {
  tabIndex?: number;
};

export const SideNavToggle = forwardRef<HTMLButtonElement, Props>(
  ({tabIndex = 0}, ref) => {
    const {isOpen, onToggle} = useSideNav();

    const {progress: progressIwRegisterProperty} = useTour({
      tour: GettingStartedTour.IwRegisterProperty,
      step: 'openMenu',
    });
    const {progress: progressIwCreateProject} = useTour({
      tour: GettingStartedTour.IwCreateProject,
      step: 'openMenu',
    });
    const {progress: progressIwCreteJob} = useTour({
      tour: GettingStartedTour.IwCreateJob,
      step: 'openMenu',
    });
    const {progress: progressSaCreateProject} = useTour({
      tour: GettingStartedTour.SaCreateProject,
      step: 'openMenu',
    });
    const {progress: progressSaSearchConsoleIntegration} = useTour({
      tour: GettingStartedTour.SaSearchConsoleIntegration,
      step: 'openMenu',
    });
    const {progress: progressSaCreateView} = useTour({
      tour: GettingStartedTour.SaCreateView,
      step: 'openMenu',
    });
    const {progress: progressSaCreateUrlGroup} = useTour({
      tour: GettingStartedTour.SaCreateUrlGroup,
      step: 'openMenu',
    });
    const {progress: progressSaCreateNavigationalQueryGroup} = useTour({
      tour: GettingStartedTour.SaCreateNavigationalQueryGroup,
      step: 'openMenu',
    });
    const handleClick = () => {
      onToggle();
      progressIwRegisterProperty();
      progressIwCreateProject();
      progressIwCreteJob();
      progressSaCreateProject();
      progressSaSearchConsoleIntegration();
      progressSaCreateView();
      progressSaCreateUrlGroup();
      progressSaCreateNavigationalQueryGroup();
    };

    const {t} = useTranslation();
    const label = isOpen
      ? t('general.メニューを閉じる')
      : t('general.メニューを開く');

    const [isHover, setIsHover] = useState(false);
    const Icon = (() => {
      if (isOpen) {
        return MdClose;
      }
      if (isHover) return MdMenuOpen;
      return MdMenu;
    })();

    // dont show side navigation on onboarding pages
    const router = useRouter();
    if (router.pathname.startsWith('/onboarding')) {
      return null;
    }

    return (
      <Tooltip label={label}>
        <IconButton
          ref={ref}
          onClick={handleClick}
          aria-label={label}
          variant="ghost"
          tabIndex={tabIndex}
          color="color.hambleA11y"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Icon size="24px" />
        </IconButton>
      </Tooltip>
    );
  }
);
SideNavToggle.displayName = 'SideNavToggle';
