import {useApolloClient} from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import {TOURMALINE_TOKEN_LOCAL_STORAGE_KEY} from 'common/storage';
import {pagesPath} from 'lib/$path';
import {useViewerQuery} from 'lib/gql/generated';
import {useRouter} from 'next/router';
import {useCallback, useRef} from 'react';
import {useTranslation} from 'next-i18next';
import {useOrg} from 'common/useOrg';
import {Env} from 'env/env';

export const UserMenu = () => {
  const client = useApolloClient();
  const router = useRouter();

  const handleSignOutClick = useCallback(() => {
    client.clearStore();
    localStorage.removeItem(TOURMALINE_TOKEN_LOCAL_STORAGE_KEY);
    router.push(pagesPath.login.$url());
  }, [client, router]);

  const signoutButtonRef = useRef<HTMLButtonElement>(null);
  const {data} = useViewerQuery();
  const {t} = useTranslation();

  const {currentOrgDatabaseId, defaultOrgDatabaseId} = useOrg();

  if (!data?.viewer) return null;
  if (!currentOrgDatabaseId && !defaultOrgDatabaseId) return null;

  return (
    <Box>
      <Popover closeOnBlur>
        <PopoverTrigger>
          <IconButton
            aria-label={t('general.ユーザーメニューを開く')}
            rounded="full"
            variant="ghost"
            sx={{
              _hover: {
                background: '#F4F4F4',
              },
              _active: {
                background: '#F4F4F4',
              },
            }}
          >
            <Avatar
              size="sm"
              name={data.viewer.name}
              src={data.viewer.picture}
            />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent
          sx={{
            marginRight: '15px',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
            borderRadius: 'none',
          }}
        >
          <PopoverBody
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '25px',
            }}
          >
            <Avatar
              width="96px"
              height="96px"
              size="xl"
              name={data.viewer.name}
              src={data.viewer.picture}
            />
            <Stack>
              <Text fontSize="lg" fontWeight="bold">
                {data.viewer.name}
              </Text>
              <Text fontSize="sm">{data.viewer.email}</Text>
            </Stack>
            <Button
              ref={signoutButtonRef}
              variant="danger"
              width="192px"
              onClick={handleSignOutClick}
              // if demo, disable
              isDisabled={Env.ENV === 'demo'}
            >
              {t('general.ログアウト')}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
