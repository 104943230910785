import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon_-_notification_svg__a)">
      <g clipPath="url(#icon_-_notification_svg__b)">
        <path
          fill="#EA4335"
          d="M11.25 13.03h1.5v-4.9h-1.5v4.9Zm.75 2.7c.23 0 .43-.07.57-.23.14-.16.23-.34.23-.57a.769.769 0 0 0-.8-.79c-.23 0-.43.07-.57.23-.15.15-.23.34-.23.57a.769.769 0 0 0 .8.79Zm-7.75 2.98v-1.5h2V9.96c0-1.37.42-2.57 1.26-3.61.84-1.04 1.92-1.71 3.24-2.01v-.7c0-.35.12-.65.36-.89s.54-.36.89-.36.65.12.89.36.36.54.36.89v.7c1.32.3 2.4.97 3.24 2.01.84 1.04 1.26 2.25 1.26 3.61v7.25h2v1.5H4.25ZM12 21.63c-.5 0-.93-.17-1.27-.52a1.73 1.73 0 0 1-.53-1.27h3.6c0 .5-.17.93-.52 1.27-.35.35-.77.52-1.27.52H12Zm-4.25-4.42h8.5V9.96c0-1.18-.41-2.19-1.24-3.01-.83-.82-1.83-1.24-3.01-1.24-1.18 0-2.19.41-3.01 1.24-.82.83-1.24 1.83-1.24 3.01v7.25Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="icon_-_notification_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="icon_-_notification_svg__b">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconNotification;
