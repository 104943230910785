import {checkboxAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: '#C4C4C4',
    borderRadius: 0,
    width: '18px',
    height: '18px',
    outline: '3px solid transparent',
    _checked: {
      backgroundColor: 'amethyst.prior',
      borderColor: 'amethyst.prior',
    },
    _hover: {
      backgroundColor: '#F4F4F4 !important',
      borderColor: '#C4C4C4 !important',
      _checked: {
        backgroundColor: 'amethyst.light !important',
        borderColor: 'amethyst.light !important',
      },
    },
    _focusWithin: {
      backgroundColor: 'white',
      borderColor: '#C4C4C4',
      outlineColor: 'var(--chakra-ring-color)',
    },
    _disabled: {
      opacity: 0.5,
      backgroundColor: 'white',
      borderColor: '#C4C4C4',
      _checked: {
        backgroundColor: 'amethyst.prior',
        borderColor: 'amethyst.prior',
      },
    },
    _indeterminate: {
      backgroundColor: 'white',
      borderColor: '#C4C4C4',
    },
  },
  icon: {
    color: 'white',
  },
});

const pill = definePartsStyle({
  control: {
    // display: 'none', // To focus the label when using keyboard navigation, display: none is not allowed.
    visibility: 'hidden',
    width: '0',
    height: '0',
  },
  icon: {
    display: 'none',
  },
  container: {
    alignItems: 'center',
    pl: '18px',
    pr: '20px',
    height: '40px',
    fontWeight: 'normal',
    color: 'color.regular',
    background: '#F4F4F4',
    borderRadius: 'full',
    border: '1px solid',
    borderColor: '#C4C4C4',
    _hover: {
      background: '#D9D9D9',
      _disabled: {
        background: '#F4F4F4',
      },
    },
    _checked: {
      background: 'white',
      borderColor: 'amethyst.prior',
      color: 'amethyst.prior',
    },
    _focusWithin: {
      outlineWidth: '3px',
      outlineStyle: 'solid',
      outlineColor: 'var(--chakra-ring-color)',
    },
  },
  label: {
    ml: '0',
    fontSize: 'text2',
  },
});

const rect = definePartsStyle({
  control: {
    ...baseStyle.control,
    ml: '10px',
  },
  icon: {
    ...baseStyle.icon,
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    pt: '18px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'transparent',
    rounded: 0,
    transition: 'all 0.2s',
    _hover: {
      borderColor: 'amethyst.prior',
      background: 'amethyst.extraLight',
    },
    _focusWithin: {
      outlineWidth: '3px',
      outlineStyle: 'solid',
      outlineColor: 'var(--chakra-ring-color)',
      zIndex: 1,
    },
  },
});

const rectRadio = definePartsStyle({
  ...rect,
  control: {
    ...rect.control,
    rounded: 'full',
    _checked: {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '8px',
        height: '8px',
        backgroundColor: 'white',
        rounded: 'full',
      },
    },
    _hover: {
      _checked: {
        background: 'amethyst.light !important',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '8px',
          height: '8px',
          backgroundColor: 'white',
          rounded: 'full',
        },
      },
    },
  },
  icon: {
    display: 'none',
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    pill,
    rect,
    rectRadio,
  },
});
