import {menuAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    outline: '3px solid transparent',
    _focus: {
      outlineColor: 'var(--chakra-ring-color)',
    },
    // NOTE: MenuButtonではright iconを指定できないので、以下のようにしてください
    // <MenuButton as={Button} variant='menuButton' rightIcon={<MdArrowDropDown />}>...
    // 定義は./button.tsにあります
  },
  list: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: 'none',
    width: '316px',
    maxH: '360px',
    overflowY: 'auto',
  },
  item: {
    minHeight: '40px',
    fontSize: 'text1',
    color: 'color.regular',
    gap: '10px',
    _hover: {
      backgroundColor: '#F4F4F4',
    },
    _focus: {
      backgroundColor: '#F4F4F4',
    },
  },
});

export const menuTheme = defineMultiStyleConfig({baseStyle});
