import {extendTheme} from '@chakra-ui/react';
import {colors} from './colors';
import {fontSizes} from './fontSizes';
import {boxTheme} from './components/box';
import {buttonTheme} from './components/button';
import {dividerTheme} from './components/divider';
import {headingTheme} from './components/heading';
import {inputTheme} from './components/input';
import {linkTheme} from './components/link';
import {menuTheme} from './components/menu';
import {modalTheme} from './components/modal';
import {selectTheme} from './components/select';
import {tabsTheme} from './components/tabs';
import {textTheme} from './components/text';
import {textareaTheme} from './components/textarea';
import {checkboxTheme} from './components/checkbox';
import {tableTheme} from './components/table';
import {tagTheme} from './components/tag';
import {cardTheme} from './components/card';
import {accordionTheme} from './components/accordion';
import {radioTheme} from './components/radio';
import {popoverTheme} from './components/popover';
import {switchTheme} from './components/switch';

const themeExtensions = {
  fonts: {
    heading: "'Inter', sans-serif",
    body: "'Inter', sans-serif",
  },
  colors,
  fontSizes,
  components: {
    Divider: dividerTheme,
    Modal: modalTheme,
    Tabs: tabsTheme,
    Button: buttonTheme,
    Input: inputTheme,
    Textarea: textareaTheme,
    Menu: menuTheme,
    Select: selectTheme,
    Text: textTheme,
    Heading: headingTheme,
    Link: linkTheme,
    Box: boxTheme,
    Checkbox: checkboxTheme,
    Table: tableTheme,
    Tag: tagTheme,
    Card: cardTheme,
    Accordion: accordionTheme,
    Radio: radioTheme,
    Popover: popoverTheme,
    Switch: switchTheme,
  },
  zIndex: {
    drawer: 1000,
    drawerOverlay: 900,
  },
  mediaQuery: {
    isMobile: '(max-width: 768px)',
  },
  sizes: {
    appBarHeight: '50px',
  },
  space: {
    regular: '20px',
    small: '10px',
    large: '30px',
    margin: '15px',
    gutters: '30px',
  },
  shadows: {
    outline: '0 0 0 2px var(--chakra-ring-color)',
  },
  styles: {
    global: {
      ':root': {
        '--chakra-ring-color': colors.amethyst.mediumLight,
      },
      '*': {
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          rounded: 'full',
          background: 'rgba(0, 0, 0, 0.2)',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.4)',
          },
        },
      },
    },
  },
} as const;

export type ThemeType = typeof themeExtensions;

export const theme = extendTheme(themeExtensions) as ThemeType &
  Record<string, unknown>;
