import {useApolloClient} from '@apollo/client';
import {Env} from 'env/env';
import {useQueryParamOrgId} from 'features/org/store';
import {UserFragment, UserOrganizationRole} from 'lib/gql/generated';
import {useTranslation} from 'next-i18next';

/**
 * If the env is demo or the user is a guest, then the user cannot mutate anything.
 * This hook using apollo client cache directly.
 * @returns
 * - isMutationUnpermitted: Whether the user cannot mutate anything.
 * - reason: The reason why the user cannot mutate anything.
 */
export const useMutationUnpermitted = () => {
  const client = useApolloClient();
  // NOTE: viewer query has no argument and fetch-policy: cache-first is not working. So directly access to cache.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = (client.cache.extract() as any)?.ROOT_QUERY?.viewer as
    | UserFragment
    | undefined;
  const {orgId} = useQueryParamOrgId();
  const role = data?.organizations?.find(o => o.organizationID === orgId)?.role;

  const isDemo = Env.ENV === 'demo';
  const isGuest = role === UserOrganizationRole.Guest;

  // If the env is demo or the user is a guest, then the user cannot mutate anything.
  const isMutationUnpermitted = isDemo || isGuest;

  const {t} = useTranslation();
  const reason = isDemo
    ? t('general.unpermittedReason.デモ環境ではこの操作は行えません。')
    : isGuest
    ? t('general.unpermittedReason.ゲストユーザーはこの操作を行えません。')
    : '';

  return {
    isMutationUnpermitted,
    reason,
  };
};
