/**
 * Check if a value is not null or undefined
 * @param value
 * @returns True if the value is not null or undefined
 */
export const isNotNullish = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined;

/**
 * Filter out null or undefined values from an array
 * @param values
 * @returns Array of non-nullish values
 */
export const filterIsNotNull = <T>(values: T[]): NonNullable<T>[] =>
  values.filter(isNotNullish);
