import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconSessionExplorer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <path d="M13.13 17.38a.64.64 0 1 0 0-1.28.64.64 0 0 0 0 1.28ZM7.11 12.64a.64.64 0 1 0 0-1.28.64.64 0 0 0 0 1.28ZM15.84 9.62a.64.64 0 1 0 0-1.28.64.64 0 0 0 0 1.28ZM14.83 7.7c.09-.07.2-.11.3-.16-.89-.63-1.97-1-3.14-1a5.47 5.47 0 0 0-5.22 3.86 1.666 1.666 0 0 1 1.16.2c.59-1.69 2.17-2.91 4.06-2.91.86 0 1.66.26 2.33.7.1-.26.27-.5.5-.68l.01-.01ZM6.77 13.6a5.481 5.481 0 0 0 4.91 3.85c-.05-.11-.11-.21-.14-.33-.07-.28-.05-.57.03-.85a4.296 4.296 0 0 1-3.63-2.87c-.25.15-.53.23-.83.23-.11 0-.23-.01-.33-.04l-.01.01ZM18.04 14.29l-1.26.27c.15-.29.29-.59.39-.9.19-.59.28-1.13.28-1.66 0-.69-.13-1.35-.37-1.96-.07.08-.15.16-.24.23-.23.18-.51.28-.8.32.16.45.26.92.26 1.41 0 .4-.07.83-.23 1.31-.09.27-.21.54-.35.79l-.33-1.5-1.12.24.59 2.73c.03.15.12.28.25.36.09.06.2.09.31.09.04 0 .08 0 .12-.01l2.73-.59-.24-1.12.01-.01Z" />
    <path d="m10.64 14.18-.82-.82 1.6-1.6V8.59h1.15v3.65l-1.93 1.94Z" />
    <path d="M12 21.97c-.43 0-.87-.1-1.22-.3l-6.55-3.78c-.69-.4-1.22-1.31-1.22-2.11V8.21c0-.8.52-1.71 1.22-2.11l6.55-3.78c.69-.4 1.74-.4 2.43 0l6.55 3.78c.69.4 1.22 1.31 1.22 2.11v7.57c0 .8-.52 1.71-1.22 2.11l-6.55 3.78c-.35.2-.78.3-1.22.3H12Zm0-18.54c-.19 0-.38.04-.51.11L4.94 7.32c-.26.15-.52.59-.52.89v7.57c0 .3.26.74.52.89l6.55 3.78c.26.15.77.15 1.03 0l6.55-3.78c.26-.15.52-.59.52-.89V8.21c0-.3-.26-.74-.52-.89l-6.55-3.78c-.13-.08-.32-.11-.52-.11Z" />
  </svg>
);
export default SvgIconSessionExplorer;
