import {useTranslation} from 'next-i18next';
import {useCallback} from 'react';
import {
  Resource,
  Operation,
  ResourceI18n,
  OperationI18n,
  OperationFailI18n,
} from './constants';

export type GetToastConentOptions = {
  resource: Resource;
  operation: Operation;
  isError?: boolean;
  isNetworkError?: boolean;
};

export const useToastContent = () => {
  const {t} = useTranslation('toast');

  const getToastContent = useCallback(
    ({
      resource,
      operation,
      isError,
      isNetworkError,
    }: GetToastConentOptions): string => {
      if (isNetworkError) return t('ネットワークエラーが発生しました');

      if (operation === Operation.SEND_INVITATION) {
        if (isError) {
          return t(OperationFailI18n[operation]);
        } else {
          return t(OperationI18n[operation]);
        }
      } else {
        const resourceI18n = t(ResourceI18n[resource]);
        if (isError) {
          return t(OperationFailI18n[operation], {resource: resourceI18n});
        } else {
          return t(OperationI18n[operation], {resource: resourceI18n});
        }
      }
    },
    [t]
  );

  const loadingContent = t('処理中...');

  return {getToastContent, loadingContent};
};
