import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#5F5F5F"
      d="M7.3 22.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 5.5 20.7V3.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h9.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v17.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H7.3ZM7 17.775V20.7c0 .067.033.133.1.2s.133.1.2.1h9.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-2.925H7Zm5 2.5c.25 0 .458-.088.625-.263a.889.889 0 0 0 .25-.637.862.862 0 0 0-.25-.613A.828.828 0 0 0 12 18.5a.828.828 0 0 0-.625.262.862.862 0 0 0-.25.613c0 .25.083.462.25.637a.827.827 0 0 0 .625.263Zm-5-4h10V5.75H7v10.525ZM7 4.25h10V3.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H7.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v.95Zm0 13.525V21 17.775ZM7 4.25V3v1.25Z"
    />
  </svg>
);
export default SvgIconPhone;
