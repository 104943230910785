import {cardAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    boxShadow: '0px 1px 3px 1px rgba(101, 101, 101, 0.16)',
    borderRadius: 0,
    color: 'color.regular',
    fontSize: 'text2',
    rounded: 'lg',
  },
  header: {
    fontSize: 'heading2',
    color: 'color.regular',
    fontWeight: 'bold',
    flexWrap: 'wrap',
    display: 'flex',
    gap: '10px',
  },
});

const gradient = {
  container: {
    bgGradient: 'linear(to-br, amethyst.extraLight, white)',
  },
};

export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    gradient,
  },
});
