import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {IconIndexWorker} from 'common/icons';
import {GettingStartedTour, SideNavigationOrgFragment} from 'lib/gql/generated';
import {useCallback, useState} from 'react';
import {MdArrowRight} from 'react-icons/md';
import NextLink from 'next/link';
import {pagesPath} from 'lib/$path';
import {decodeId} from 'common/convertId';
import {useQueryParamOrgId} from 'features/org/store';
import {useRouter} from 'next/router';
import {atomWithStorage} from 'jotai/utils';
import {useAtom} from 'jotai';
import {useTranslation} from 'next-i18next';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';

type Props = {
  projects: SideNavigationOrgFragment['indexWorkerProjects'];
};

const isOpenAtom = atomWithStorage('indexWorkerProjects', true);
const SHOW_SIZE = 5;

export const IndexWorkerProjects = ({projects}: Props) => {
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const [isHover, setIsHover] = useState(false);
  const {orgDatabaseId} = useQueryParamOrgId();
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const iwProjects = projects.filter(
    p => p.__typename === 'IndexWorkerProject'
  );
  const count = iwProjects.length;

  if (!orgDatabaseId) return null;

  return (
    <Stack spacing="2px">
      <Button
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={isOpen ? onClose : onOpen}
        size="sm"
        leftIcon={
          <Box w="18px" h="18px">
            {isHover ? (
              <MdArrowRight
                size="100%"
                style={{
                  transition: 'transform 0.2s',
                  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            ) : (
              <IconIndexWorker viewBox="2 2 20 20" width="100%" height="100%" />
            )}
          </Box>
        }
        variant="ghost"
        rightIcon={
          isOpen ? undefined : (
            <Badge
              color="amethyst.prior"
              background="amethyst.extraLight"
              fontSize="memo1"
              px="6px"
              py="2px"
              rounded="lg"
            >
              {count}
            </Badge>
          )
        }
        fontWeight="bold"
      >
        {t('indexWorkerProjects')}
      </Button>
      {isOpen &&
        iwProjects.map((p, i) =>
          p.__typename === 'IndexWorkerProject' ? (
            <IndexWorkerProject
              key={p.id}
              project={p}
              showTourTooltip={i === 0}
            />
          ) : null
        )}
      {isOpen && iwProjects.length === 0 && (
        <Box p="2" fontSize="text2" color="color.hambleA11y">
          {t('noIndexWorkerProjects')}
        </Box>
      )}
    </Stack>
  );
};

const IndexWorkerProject = ({
  project,
  showTourTooltip = false,
}: {
  project: SideNavigationOrgFragment['indexWorkerProjects'][number];
  showTourTooltip?: boolean;
}) => {
  const {orgDatabaseId} = useQueryParamOrgId();
  const [isHover, setIsHover] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure({
    defaultIsOpen: false,
  });
  const router = useRouter();
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const {progress, Tooltip} = useTourProgress(showTourTooltip);

  if (!orgDatabaseId) return null;
  if (project.__typename !== 'IndexWorkerProject') return null;

  const pushToProject = () =>
    router.push(
      pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(decodeId(project.id).docId)
        .index_workers.$url()
    );

  return (
    <Stack spacing="0">
      <Tooltip>
        <Button
          size="sm"
          as="div" // prevent nesting interactive elements
          tabIndex={0}
          role="button"
          key={project.id}
          variant="ghost"
          justifyContent="flex-start"
          w="100%"
          aria-current={
            router.pathname ===
              pagesPath.orgs
                ._orgId(orgDatabaseId)
                .projects._projectId(decodeId(project.id).docId)
                .index_workers.$url().pathname &&
            router.query.projectId === decodeId(project.id).docId
              ? true
              : undefined
          }
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onKeyDown={e => {
            // space: toggle open/close
            if (e.key === ' ') {
              e.preventDefault();
              isOpen ? onClose() : onOpen();
            }
            if (e.key === 'Enter') {
              e.preventDefault();
              onOpen();
              pushToProject();
            }
          }}
          onClick={e => {
            e.preventDefault();
            onOpen();
            pushToProject();
            progress();
          }}
          leftIcon={
            <Box
              w="18px"
              h="18px"
              rounded="sm"
              backgroundColor={isHover ? '' : project.color}
              _hover={{
                background: '#D9D9D9',
              }}
              onClick={e => {
                // open/close on click icon
                e.preventDefault();
                e.stopPropagation();
                isOpen ? onClose() : onOpen();
              }}
            >
              {isHover ? (
                <MdArrowRight
                  size="18px"
                  style={{
                    transition: 'transform 0.2s',
                    transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />
              ) : null}
            </Box>
          }
        >
          {project.name}
        </Button>
      </Tooltip>
      <HStack spacing="2px">
        <Divider orientation="vertical" ml="20px" />
        <Stack spacing="0" overflow="hidden" flex="1" p="2px" m="-2px">
          {isOpen && !project.indexWorkers.length && (
            <Box p="2" fontSize="text2" color="color.hambleA11y">
              {t('noJobs')}
            </Box>
          )}
          {isOpen && project.indexWorkers.length > 0 && (
            <Jobs
              project={project}
              orgDatabaseId={orgDatabaseId}
              projectDatabaseId={decodeId(project.id).docId}
            />
          )}
        </Stack>
      </HStack>
    </Stack>
  );
};

const Jobs = ({
  project,
  orgDatabaseId,
  projectDatabaseId,
}: {
  project: SideNavigationOrgFragment['indexWorkerProjects'][number];
  orgDatabaseId: string;
  projectDatabaseId: string;
}) => {
  const [showAll, setShowAll] = useState(false);
  const jobs =
    project.__typename === 'IndexWorkerProject' ? project.indexWorkers : [];
  const shownJobs = showAll ? jobs : jobs.slice(0, SHOW_SIZE);
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const router = useRouter();

  if (jobs.length === 0) return null;

  return (
    <>
      {shownJobs.map(iw => (
        <NextLink
          key={iw.id}
          legacyBehavior
          href={pagesPath.orgs
            ._orgId(orgDatabaseId)
            .projects._projectId(projectDatabaseId)
            .index_workers._indexWorkerId(decodeId(iw.id).docId)
            .$url()}
          passHref
        >
          <Button
            as="a"
            size="sm"
            variant="ghost"
            justifyContent="flex-start"
            leftIcon={
              <Box w="18px" h="18px" background={iw.color} rounded="sm" />
            }
            aria-current={
              router.pathname ===
                pagesPath.orgs
                  ._orgId(orgDatabaseId)
                  .projects._projectId(projectDatabaseId)
                  .index_workers._indexWorkerId(decodeId(iw.id).docId)
                  .$url().pathname &&
              router.query.projectId === projectDatabaseId &&
              router.query.indexWorkerId === decodeId(iw.id).docId
                ? true
                : undefined
            }
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {iw.name}
            </Box>
          </Button>
        </NextLink>
      ))}
      {jobs.length > SHOW_SIZE && !showAll && (
        <Button
          size="sm"
          variant="ghost"
          onClick={() => setShowAll(true)}
          justifyContent="flex-start"
          color="color.hambleA11y"
        >
          {t('showAll')}
        </Button>
      )}
    </>
  );
};

const useTourProgress = (showTour: boolean) => {
  const {progress} = useTour({
    tour: GettingStartedTour.IwCreateJob,
    step: 'clickIndexWorkerProject',
  });
  const handleProgress = useCallback(() => {
    if (showTour) progress();
  }, [progress, showTour]);

  const Tooltip = useCallback(
    ({children}: {children: React.ReactNode}) => {
      if (!showTour) return <>{children}</>;
      return (
        <TourTooltip
          tour={GettingStartedTour.IwCreateJob}
          step="clickIndexWorkerProject"
        >
          {children}
        </TourTooltip>
      );
    },
    [showTour]
  );

  return {progress: handleProgress, Tooltip};
};
