import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {pagesPath} from 'lib/$path';
import {useNotificationsQuery} from 'lib/gql/generated';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {MdNotifications, MdSettings} from 'react-icons/md';
import {useQueryParamOrgId} from 'features/org/store';

export const NotificationLinkIcon = () => {
  const {orgId, orgDatabaseId} = useQueryParamOrgId();
  const {data} = useNotificationsQuery({
    variables: {
      first: 3,
      unread: true, // 未読のみ
      archived: false,
      orgId: orgId,
    },
    fetchPolicy: 'no-cache',
    skip: !orgId,
  });
  const {t} = useTranslation('layout', {
    keyPrefix: 'AppBar.NotificationLinkIcon',
  });
  const hasUnreadInfo = !!data?.viewer?.notifications.edges.length;

  const router = useRouter();
  if (router.pathname === pagesPath.onboarding.$url({query: {}}).pathname)
    return null;

  return (
    <Menu>
      <Tooltip label={t('notifications')}>
        <MenuButton
          as={IconButton}
          icon={<MdNotifications size="24px" />}
          aria-label={t('notifications')}
          variant="ghost"
          _before={
            hasUnreadInfo
              ? {
                  content: '""',
                  position: 'absolute',
                  width: '6px',
                  height: '6px',
                  backgroundColor: 'amethyst.prior',
                  borderRadius: '50%',
                  border: '2px solid white',
                  top: '6px',
                  right: '6px',
                }
              : undefined
          }
        />
      </Tooltip>
      {orgDatabaseId && (
        <MenuList>
          {data?.viewer?.notifications.edges.slice(0, 3).map(edge => (
            <Link
              href={pagesPath.orgs._orgId(orgDatabaseId).notification.$url({
                query: {
                  notificationId: edge.node.id,
                },
              })}
              passHref
              key={edge.node.id}
              legacyBehavior
            >
              <MenuItem
                as="a"
                fontSize="text2"
                color="amethyst.prior"
                fontWeight="bold"
              >
                {edge.node.title}
              </MenuItem>
            </Link>
          ))}
          {data?.viewer?.notifications.edges.length === 0 && (
            <Text display="block" p="20px">
              {t('noNotifications')}
            </Text>
          )}
          <Link
            href={pagesPath.orgs
              ._orgId(orgDatabaseId)
              .notification.$url({query: {}})}
            passHref
            legacyBehavior
          >
            <MenuItem
              as="a"
              fontSize="clickable1"
              fontWeight="bold"
              isDisabled={!orgDatabaseId}
            >
              {t('showAll')}
            </MenuItem>
          </Link>
          <MenuDivider />
          <Link
            href={pagesPath.orgs
              ._orgId(orgDatabaseId)
              .notification.settings.$url()}
            passHref
            legacyBehavior
          >
            <MenuItem as="a" fontSize="clickable1" fontWeight="bold">
              <MdSettings size="24px" />
              {t('settings')}
            </MenuItem>
          </Link>
        </MenuList>
      )}
    </Menu>
  );
};
