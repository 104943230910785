import {Link, useToast} from '@chakra-ui/react';
import {useQueryParamOrgId} from 'features/org/store';
import {pagesPath} from 'lib/$path';
import {useTranslation} from 'next-i18next';
import NextLink from 'next/link';
import {useRef} from 'react';

export const useTourCompletedToast = () => {
  const toast = useToast();
  const {t} = useTranslation('tour');
  const {orgDatabaseId} = useQueryParamOrgId();
  const toastId = useRef<string | number | undefined>();

  const updateToastId = (id: string | number | undefined) => {
    toastId.current = id;
  };

  const showTourCompletedToast = () => {
    if (!orgDatabaseId) return;
    if (toastId.current) return;

    const id = toast({
      position: 'bottom',
      isClosable: true,
      duration: null,
      title: t('toast.tourCompleted'),
      description: (
        <NextLink
          href={pagesPath.orgs._orgId(orgDatabaseId).getting_started.$url()}
          passHref
          legacyBehavior
        >
          <Link
            textDecor="underline"
            color="white"
            onMouseDown={() => {
              if (toastId.current) {
                toast.close(toastId.current);
              }
            }}
          >
            {t('toast.backToGettingStarted')}
          </Link>
        </NextLink>
      ),
      status: 'success',
      onCloseComplete: () => {
        updateToastId(undefined);
      },
    });

    updateToastId(id);
  };

  return {showTourCompletedToast};
};
