import {useMobile} from 'common/responsive/useMobile';
import {atom, useAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';

export const MIN_NAV_WIDTH = 200;
const navWidthAtom = atomWithStorage('navWidth', 280);
export const MAX_NAV_WIDTH = 400;
const globalNavOpenAtom = atom(true);
export const useSideNav = () => {
  const isMobile = useMobile();
  const [isOpen, setIsOpen] = useAtom(globalNavOpenAtom);
  const onToggle = () => setIsOpen(prev => !prev);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [navWidth, setNavWidth] = useAtom(navWidthAtom);

  const contentWidth =
    isOpen && !isMobile ? `calc(100% - ${navWidth}px)` : '100%';
  const contentLeft = isOpen && !isMobile ? `${navWidth}px` : '0px';

  return {
    isOpen,
    onToggle,
    onOpen,
    onClose,
    contentWidth,
    contentLeft,
    navWidth,
    setNavWidth,
  };
};
