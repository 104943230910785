import Script from 'next/script';
import {Env} from 'env/env';
import {useEffect} from 'react';

const containerId = Env.GTM_CONTAINER_ID;

export const TagManager = () => {
  useEffect(() => {
    if (!containerId) return;
    if (containerId === 'null') return;
    if (typeof window !== 'undefined') {
      if (!window) return;
      window.dataLayer = window.dataLayer || [];
    }
  }, []);

  if (!containerId) return null;

  return (
    <>
      <PrdTagManager containerId={containerId} />
    </>
  );
};

const PrdTagManager = ({containerId}: {containerId: string}) => (
  <Script
    id="gtm"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${containerId}');
      `,
    }}
  />
);

export default TagManager;
