import {LayoutProps} from '@chakra-ui/react';
import {ReactNode} from 'react';
import {Dialog} from './Dialog';

export type InputConfirmMode = 'input' | 'confirm';
export type InputConfirmCompleteMode = 'input' | 'confirm' | 'complete';

type Props<T extends ReactNode> = {
  isOpen: boolean;
  onClose: () => void;
  mode: T extends ReactNode ? InputConfirmCompleteMode : InputConfirmMode;
  input: ReactNode;
  confirm: ReactNode;
  complete?: T extends ReactNode ? ReactNode : never;
  onBack: () => void;
  title: string;
  titleHelp?: ReactNode;
  width?: LayoutProps['maxW'];
  height?: LayoutProps['height'];
  h?: LayoutProps['height'];
};

export const InputConfirmDialog = <T extends ReactNode>({
  isOpen,
  onClose,
  mode,
  input,
  confirm,
  complete = null,
  onBack,
  title,
  titleHelp,
  width = '860px',
  height,
  h,
}: Props<T>) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onBack={mode === 'confirm' ? onBack : undefined}
      title={title}
      titleHelp={titleHelp}
      width={width}
      height={height}
      h={h}
      resetScrollKey={mode}
    >
      {mode === 'input' ? input : mode === 'confirm' ? confirm : complete}
    </Dialog>
  );
};
