import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconRecyclerbin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#565656"
      d="M7.5 21c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5.5 19V6h-1V4h5V3h6v1h5v2h-1v13c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 17.5 21h-10Zm10-15h-10v13h10V6Zm-8 11h2V8h-2v9Zm4 0h2V8h-2v9Z"
    />
  </svg>
);
export default SvgIconRecyclerbin;
