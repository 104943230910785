import {z} from 'zod';

export const orgNameSchema = z
  .string()
  .min(1, {
    message: '必須項目です',
  })
  .max(40, {
    message: '40文字以内で入力してください',
  });
