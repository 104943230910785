import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconUserAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <g clipPath="url(#icon_-_User_Analytics_svg__a)">
      <path d="M9.11 10.59c-.18.18-.27.4-.27.66s.09.48.27.66c.18.18.4.27.66.27s.48-.09.66-.27c.18-.18.27-.4.27-.66s-.09-.48-.27-.66a.898.898 0 0 0-.66-.27c-.26 0-.48.09-.66.27Z" />
      <path d="m19.77 6.11-6.55-3.78c-.69-.4-1.74-.4-2.43 0L4.24 6.11c-.69.4-1.22 1.31-1.22 2.11v7.57c0 .8.52 1.71 1.22 2.11l6.55 3.78c.35.2.78.3 1.22.3.44 0 .87-.1 1.22-.3l6.55-3.78c.69-.4 1.22-1.31 1.22-2.11V8.22c0-.8-.52-1.71-1.22-2.11h-.01Zm-.18 2.11v7.16h-2.14c-.05-.25-.16-.49-.32-.7-.33-.42-.83-.66-1.36-.66-.11 0-.22.03-.32.05l-.9-2.07.9-2.06c.11.02.22.04.33.04.39 0 .77-.13 1.07-.37.75-.59.88-1.69.29-2.44-.1-.13-.22-.24-.35-.33l.3-.68 2.01 1.16c.26.15.52.59.52.89l-.03.01Zm-3.84 8.17c-.19 0-.37-.08-.5-.24a.626.626 0 0 1 .11-.89c.12-.09.26-.14.39-.14.19 0 .37.08.5.24.22.28.17.68-.11.89-.12.09-.26.14-.39.14Zm.5-8.54c.22.28.17.68-.11.89-.12.09-.26.14-.39.14-.19 0-.37-.08-.5-.24a.626.626 0 0 1 .11-.89c.12-.09.26-.14.39-.14.19 0 .37.08.5.24Zm-3.73 12.61c-.26.15-.77.15-1.03 0l-6.55-3.78c-.26-.15-.52-.59-.52-.89V8.22c0-.3.26-.74.52-.89l6.55-3.78c.13-.08.32-.11.51-.11s.39.04.52.11l3.9 2.25-.34.76c-.1-.02-.21-.04-.32-.04-.39 0-.77.13-1.07.37-.75.59-.88 1.69-.29 2.44.1.13.22.23.35.32l-.86 1.98h-.86a3.268 3.268 0 0 0-3.24-2.9c-1.81 0-3.28 1.47-3.28 3.28 0 1.81 1.47 3.28 3.28 3.28 1.68 0 3.05-1.27 3.24-2.9h.86l.86 1.99-.06.03c-.36.29-.6.7-.65 1.16-.05.46.07.91.36 1.28.25.32.6.53.99.62v1.36l-2.86 1.65-.01-.02ZM11.95 12c0 .49-.17.94-.44 1.3a1.01 1.01 0 0 0-.41-.44c-.33-.19-.77-.28-1.32-.28s-.99.09-1.32.28c-.2.12-.34.27-.41.44-.28-.36-.44-.81-.44-1.3 0-1.2.98-2.18 2.18-2.18 1.2 0 2.18.98 2.18 2.18h-.02Zm7.12 4.68-2.94 1.7v-.95c.25-.06.49-.15.7-.31.33-.26.53-.61.61-.99h2.06c-.1.23-.26.45-.43.55Z" />
    </g>
    <defs>
      <clipPath id="icon_-_User_Analytics_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconUserAnalytics;
