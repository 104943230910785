import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconErrorEm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#EA4335"
      stroke="#EA4335"
      d="m19.354 6.764.353-.354-.353-.354-1.41-1.41-.354-.353-.354.353L12 9.883 6.764 4.646l-.354-.353-.354.353-1.41 1.41-.353.354.353.354L9.883 12l-5.237 5.236-.353.354.353.354 1.41 1.41.354.353.354-.353L12 14.117l5.236 5.237.354.353.354-.353 1.41-1.41.353-.354-.353-.354L14.117 12l5.237-5.236Z"
    />
  </svg>
);
export default SvgIconErrorEm;
