import {radioAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    width: '20px',
    height: '20px',
    borderWidth: '1px',
    borderColor: '#C4C4C4',
    _checked: {
      borderColor: '#C4C4C4',
      background: 'amethyst.prior',
      _disabled: {
        borderColor: '#C4C4C4',
        background: 'amethyst.light',
        _before: {
          background: '#C4C4C4',
        },
      },
      _hover: {
        background: 'amethyst.regular',
        borderColor: '#C4C4C4',
      },
    },
    _disabled: {
      borderColor: '#C4C4C4',
      background: '#F4F4F4',
    },
    _focusVisible: {
      outlineColor: 'var(--chakra-ring-color)',
    },
  },
  label: {
    color: 'color.regular',
    fontSize: 'text2',
    _checked: {
      fontWeight: 'bold',
    },
    _disabled: {
      color: 'text.hambleA11y',
      opacity: 0.7,
    },
  },
});

const pill = definePartsStyle({
  control: {
    display: 'none',
  },
  container: {
    alignItems: 'center',
    px: '20px',
    height: '36px',
    fontWeight: 'normal',
    color: 'color.regular',
    background: '#F4F4F4',
    borderRadius: 'full',
    border: '1px solid',
    borderColor: '#C4C4C4',
    _hover: {
      background: '#D9D9D9',
      _disabled: {
        background: '#F4F4F4',
      },
    },
    _checked: {
      background: 'white',
      borderColor: 'amethyst.prior',
    },
  },
  label: {
    ml: '0',
    fontSize: 'text2',
    _checked: {
      fontWeight: 'normal',
      color: 'amethyst.prior',
    },
  },
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    pill,
  },
});
