import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'color.regular',
  _hover: {
    opacity: '.7',
    textDecoration: 'none',
  },
});

export const linkTheme = defineStyleConfig({baseStyle});
