import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconTodoDone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#9B4CA6"
      d="M20 15.087c.01.882-.565 1.885-1.328 2.326l-7.305 4.256c-.365.21-.852.331-1.35.331-.499 0-.974-.121-1.34-.33L1.34 17.423C.576 16.994 0 15.97 0 15.098l.021-8.512c.011-.883.643-1.72 1.418-2.162L8.666.323c.753-.43 1.937-.43 2.69 0l7.205 4.112C19.325 4.876 20 5.858 20 6.74v8.347Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m16.664 9-8.182 8.182-5.06-5.06L5.541 10l2.94 2.94 6.06-6.061L16.665 9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconTodoDone;
