import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {fontSizes} from '../fontSizes';

const baseStyle = defineStyle({
  color: 'color.regular',
  lineHeight: '170%',
});

const text1 = defineStyle({
  fontSize: fontSizes.text1,
  fontWeight: 'normal',
});
const text1Bold = defineStyle({
  ...text1,
  fontWeight: 'bold',
});
const text1Underline = defineStyle({
  ...text1,
  textDecorationLine: 'underline',
});
const text1StrikeThrough = defineStyle({
  ...text1,
  textDecorationLine: 'line-through',
});
const text2 = defineStyle({
  fontSize: fontSizes.text2,
  fontWeight: 'normal',
});
const text2Bold = defineStyle({
  ...text2,
  fontWeight: 'bold',
});
const text2Underline = defineStyle({
  ...text2,
  textDecorationLine: 'underline',
});
const memo1 = defineStyle({
  fontSize: fontSizes.memo1,
  lineHeight: '150%',
  fontWeight: 'normal',
});
const memo1Bold = defineStyle({
  ...memo1,
  fontWeight: 'bold',
});
const memo1Underline = defineStyle({
  ...memo1,
  textDecorationLine: 'underline',
});
const memo1StrikeThrough = defineStyle({
  ...memo1,
  textDecorationLine: 'line-through',
});
const title1 = defineStyle({
  fontSize: fontSizes.title1,
  fontWeight: 'normal',
});
const title1Bold = defineStyle({
  ...title1,
  fontWeight: 'bold',
});
const clickable1 = defineStyle({
  ...baseStyle,
  fontSize: fontSizes.clickable1,
});
const clickable1Bold = defineStyle({
  ...clickable1,
  fontWeight: 'bold',
});

export const textTheme = defineStyleConfig({
  baseStyle,
  variants: {
    text1,
    text1Bold,
    text1Underline,
    text1StrikeThrough,
    text2,
    text2Bold,
    text2Underline,
    memo1,
    memo1Bold,
    memo1Underline,
    memo1StrikeThrough,
    title1,
    title1Bold,
    clickable1,
    clickable1Bold,
  },
});
