import {Tag, TagProps} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';

type Props = TagProps;

/**
 * A tag that indicates a feature is beta.
 * @returns The beta feature tag.
 */
export const BetaFeatureTag = (props: Props) => {
  const {t} = useTranslation('common', {keyPrefix: 'BetaFeatureTag'});

  return (
    <Tag
      backgroundColor="amethyst.regular"
      color="white"
      rounded="full"
      border="none"
      fontWeight="bold"
      {...props}
    >
      {t('label')}
    </Tag>
  );
};
