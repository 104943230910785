import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'color.regular',
  fontSize: ['heading1', 'heading1', 'heading1', 'heading1', 'heading1'],
  lineHeight: '170%',
});

const heading1 = baseStyle;
const heading2 = defineStyle({
  ...baseStyle,
  fontSize: ['heading2', 'heading2', 'heading2', 'heading2', 'heading2'],
});
export const headingTheme = defineStyleConfig({
  baseStyle,
  variants: {
    heading1,
    heading2,
  },
});
