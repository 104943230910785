import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconUnarchive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon_-_unarchive_svg__a)">
      <path
        fill="#565656"
        d="M5.3 20.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 3.5 18.7V7.075c0-.217.03-.408.088-.575.058-.167.145-.325.262-.475l1.325-1.6a.984.984 0 0 1 .45-.325C5.808 4.033 6 4 6.2 4h11.6c.2 0 .392.033.575.1a.984.984 0 0 1 .45.325l1.3 1.6c.117.15.208.308.275.475.067.167.1.358.1.575V18.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H5.3Zm.1-14h13.2l-.85-1H6.25l-.85 1ZM5 8v10.7c0 .067.033.133.1.2s.133.1.2.1h13.4c.067 0 .133-.033.2-.1s.1-.133.1-.2V8H5Zm6.25 9.6h1.5v-4.45L14.6 15l1.05-1.05L12 10.3l-3.65 3.65L9.4 15l1.85-1.85v4.45Z"
      />
    </g>
    <defs>
      <clipPath id="icon_-_unarchive_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconUnarchive;
