import {FormControl, FormErrorMessage, Input} from '@chakra-ui/react';
import {useCreateProejctName, useCreateProjectNameError} from './store';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {GettingStartedTour} from 'lib/gql/generated';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';

export const CreateProjectNameInput = () => {
  const [name, setName] = useCreateProejctName();
  const error = useCreateProjectNameError();
  const {progress: progressIw, run: runIw} = useTour({
    tour: GettingStartedTour.IwCreateProject,
    step: 'enterProjectName',
  });
  const {progress: progressSa} = useTour({
    tour: GettingStartedTour.SaCreateProject,
    step: 'enterProjectName',
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    progressIw();
    progressSa();
  };

  return (
    <TourTooltip
      placement="end"
      tour={
        runIw
          ? GettingStartedTour.IwCreateProject
          : GettingStartedTour.SaCreateProject
      }
      step="enterProjectName"
    >
      <FormControl isInvalid={!!error}>
        <Input variant="amethyst" value={name} onChange={handleChange} />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </TourTooltip>
  );
};
