import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.saSearchConsoleIntegration'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickSearchAnalyticsProject',
  'searchConsoleIntegration',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.saSearchConsoleIntegration.openMenu',
  clickSearchAnalyticsProject:
    'tour.saSearchConsoleIntegration.clickSearchAnalyticsProject',
  searchConsoleIntegration:
    'tour.saSearchConsoleIntegration.searchConsoleIntegration',
};
