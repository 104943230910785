import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <circle cx={16} cy={11} r={2} fill="#000" />
    <circle cx={16} cy={17} r={2} fill="#000" />
    <circle cx={16} cy={23} r={2} fill="#000" />
  </svg>
);
export default SvgIconDots;
