import {Box, Spinner, Text} from '@chakra-ui/react';

type Props = {
  message?: string;
};

export const FullScreenLoader = ({message}: Props) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      w="100vw"
      h="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="10px"
    >
      <Spinner
        size="lg"
        thickness="4px"
        emptyColor="amethyst.extraLight"
        color="amethyst.prior"
      />
      {message && <Text>{message}</Text>}
    </Box>
  );
};
