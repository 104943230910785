import {
  SearchAnalyticsExportStatus,
  SearchAnalyticsExportType,
  useSaExportStatusIconQuery,
} from 'lib/gql/generated';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';
import dayjs from 'dayjs';
import {MdDownload} from 'react-icons/md';
import {Dialog} from 'common/Dialog';
import {useQueryParamOrgId} from 'features/org/store';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import ja from 'dayjs/locale/ja';
import {atom, useAtom} from 'jotai';
import {useEffect} from 'react';
import {useRouter} from 'next/router';
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const isSaExportStatusDialogOpenAtom = atom(false);

export const SaExportStatusIcon = () => {
  const [isOpen, setIsOpen] = useAtom(isSaExportStatusDialogOpenAtom);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const {t} = useTranslation('layout', {
    keyPrefix: 'AppBar.SaExportStatusIcon',
  });

  const router = useRouter();
  if (router.pathname === '/onboarding') return null;

  return (
    <>
      <Tooltip label={t('title')}>
        <IconButton aria-label={t('title')} variant="ghost" onClick={onOpen}>
          <MdDownload fontSize="24px" />
        </IconButton>
      </Tooltip>
      <SaExportStatusDialog isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const SaExportStatusDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {t} = useTranslation('layout', {
    keyPrefix: 'AppBar.SaExportStatusIcon',
  });
  return (
    <Dialog title={t('title')} isOpen={isOpen} onClose={onClose}>
      {isOpen && <SaExportStatusTable />}
    </Dialog>
  );
};

const SaExportStatusTable = () => {
  const {orgId} = useQueryParamOrgId();
  const {data, loading} = useSaExportStatusIconQuery({
    variables: {
      organizationId: orgId || '',
    },
    skip: !orgId,
    nextFetchPolicy: 'cache-only',
    pollInterval: 1000,
  });

  const {t, i18n} = useTranslation('layout', {
    keyPrefix: 'AppBar.SaExportStatusIcon',
  });

  useEffect(() => {
    if (i18n.language === 'ja') {
      dayjs.locale(ja);
    } else {
      dayjs.locale('en');
    }
  }, [i18n.language]);

  const now = dayjs();

  if (loading) {
    return (
      <Stack spacing="small">
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }
  if (!data) {
    return null;
  }
  if (data.organization?.__typename !== 'Organization') {
    return null;
  }

  // flatten & sort
  const flattened = data.organization.projects
    ?.filter(
      p =>
        p?.__typename === 'SearchAnalyticsProject' &&
        p.searchAnalytics?.exportData?.length
    )
    .map(p =>
      p.__typename === 'SearchAnalyticsProject'
        ? p.searchAnalytics?.exportData?.map(d => ({
            ...d,
            projectName: (p as {name?: string}).name,
            projectColor: (p as {color?: string}).color,
          }))
        : []
    )
    .flat()
    .sort((a, b) => {
      if (!a || !b) return 0;
      if (!a.exportedAt || !b.exportedAt) return 0;
      return dayjs(b.exportedAt).diff(dayjs(a.exportedAt));
    });

  if (!flattened?.length) return null;

  let expiredCount = 0;
  let expiredCountHidden = 0;
  const MAX_EXPIRED_COUNT = 10;

  return (
    <Stack spacing="small">
      <TableContainer maxH="60vh" overflowY="auto">
        <Table variant="amethyst">
          <Thead>
            <Tr>
              <Th>{t('project')}</Th>
              <Th>{t('exportedAt')}</Th>
              <Th>{t('format')}</Th>
              <Th>{t('statusLabel')}</Th>
              <Th>{t('expires')}</Th>
              <Th>{t('urls')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {flattened.map((exportStatus, i) => {
              const isExpired = dayjs(exportStatus?.expires).isBefore(now);
              if (isExpired) {
                expiredCount++;
              }
              if (isExpired && expiredCount > MAX_EXPIRED_COUNT) {
                expiredCountHidden++;
                return null;
              }

              return (
                <Tr key={i}>
                  <Td>
                    <Box
                      rounded="sm"
                      bg={exportStatus?.projectColor}
                      p={1}
                      w="16px"
                      h="16px"
                      mr="2"
                      display="inline-block"
                      verticalAlign="middle"
                    />
                    <span style={{verticalAlign: 'middle'}}>
                      {exportStatus?.projectName}
                    </span>
                  </Td>
                  <Td>
                    {dayjs(exportStatus?.exportedAt).isValid()
                      ? dayjs(exportStatus?.exportedAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '-'}
                  </Td>
                  <Td>
                    {exportStatus?.exportType === SearchAnalyticsExportType.Csv
                      ? 'CSV'
                      : exportStatus?.exportType ===
                        SearchAnalyticsExportType.Json
                      ? 'JSON'
                      : '-'}
                  </Td>
                  <Td
                    color={
                      exportStatus?.status === SearchAnalyticsExportStatus.Done
                        ? 'color.positive'
                        : exportStatus?.status ===
                          SearchAnalyticsExportStatus.Error
                        ? 'color.attention'
                        : 'color.regular'
                    }
                  >
                    {exportStatus?.status ===
                      SearchAnalyticsExportStatus.InProgress && (
                      <CircularProgress
                        size="16px"
                        color="amethyst.prior"
                        isIndeterminate
                        mr="2"
                      />
                    )}
                    {exportStatus?.status
                      ? t(`status.${exportStatus.status}`)
                      : '-'}
                  </Td>
                  <Td>
                    {isExpired ? (
                      <Box as="span" color="color.attention">
                        {t('expired')}
                      </Box>
                    ) : (
                      <Tooltip
                        label={
                          dayjs(exportStatus?.expires).isValid()
                            ? dayjs(exportStatus?.expires).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : '-'
                        }
                        placement="top"
                      >
                        <time dateTime={exportStatus?.expires?.toISOString()}>
                          {dayjs(exportStatus?.expires).isValid()
                            ? t('remaining') +
                              ' ' +
                              dayjs
                                .duration(
                                  dayjs(exportStatus?.expires).diff(now)
                                )
                                .humanize()
                            : '-'}
                        </time>
                      </Tooltip>
                    )}
                  </Td>
                  <Td>
                    {isExpired
                      ? null
                      : exportStatus?.urls?.map((url, i) => (
                          <Link
                            href={url}
                            key={url}
                            color="color.variation"
                            display="flex"
                            alignItems="center"
                            gap="small"
                          >
                            <MdDownload fontSize="16px" />
                            {t('download')} {i + 1}
                          </Link>
                        ))}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {expiredCountHidden > 0 && (
          <Box color="color.hambleA11y" mt="2">
            {t('expiredCountHidden', {count: expiredCountHidden})}
          </Box>
        )}
      </TableContainer>
    </Stack>
  );
};
