import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  Divider,
  Stack,
  Center,
  HStack,
  Box,
} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';
import {useCreateOrganization} from './useCreateOrganization';
import {Env} from 'env/env';
import {BackButton} from 'common/BackButton';
import {usePlan} from 'common/plan/usePlan';
import {MdInfoOutline} from 'react-icons/md';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateOrganizationDialog = ({isOpen, onClose}: Props) => {
  const {
    name,
    errorMessage,
    isConfirm,
    loading,
    handleNameChange,
    handleClose,
    handleGoToConfirm,
    handleBackToForm,
    handleSubmit,
  } = useCreateOrganization({onClose});
  const {t} = useTranslation();
  const {plan, contract} = usePlan();
  const orgs = contract?.organizations ?? [];

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxWidth="600px">
        <ModalHeader fontWeight="bold">
          {t('general.ワークスペースを作成する')}
        </ModalHeader>
        <ModalCloseButton />
        <Divider borderWidth="2px" mt="8px" />
        <ModalBody my="30px">
          {!isConfirm ? (
            <Stack gap="30px">
              <HStack spacing="small">
                <Text flexShrink={0}>{t('general.ワークスペース名称')}</Text>
                <FormControl isInvalid={!!errorMessage}>
                  <Input
                    variant="amethyst"
                    value={name}
                    onChange={handleNameChange}
                  />
                  <FormErrorMessage mt={2}>{errorMessage}</FormErrorMessage>
                </FormControl>
              </HStack>
              <Center>
                <Button
                  variant="amethyst"
                  fontSize="clickable1"
                  onClick={handleGoToConfirm}
                  width="300px"
                  isDisabled={orgs.length >= (plan?.orgCapacity ?? 0)}
                >
                  {t('general.入力内容を確認')}
                </Button>
              </Center>
              {orgs.length >= (plan?.orgCapacity ?? 0) && (
                <HStack spacing="small">
                  <MdInfoOutline size="24px" />
                  <Text variant="text2">
                    {t(
                      'general.現在のプランで作成可能なワークスペース数の上限に達しました。（ワークスペース数：{{current}}, 上限:{{limit}}）',
                      {
                        current: orgs.length,
                        limit: plan?.orgCapacity ?? 0,
                      }
                    )}
                  </Text>
                </HStack>
              )}
            </Stack>
          ) : (
            <Stack gap="30px">
              <Center>
                <HStack spacing="small">
                  <Text>{t('general.ワークスペース名称')}</Text>
                  <Text>{name}</Text>
                </HStack>
              </Center>
              <Center>
                <Button
                  variant="amethyst"
                  fontSize="clickable1"
                  onClick={handleSubmit}
                  width="300px"
                  isLoading={loading}
                  isDisabled={Env.ENV === 'demo'}
                >
                  {t('general.ワークスペースを作成する')}
                </Button>
              </Center>
              <Box>
                <BackButton onClick={handleBackToForm} />
              </Box>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
