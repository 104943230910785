import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.iwCreateJob'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickIndexWorkerProject',
  'clickAddNewJob',
  'enterJobName',
  'selectSearchConsoleProperty',
  'selectHowToRegisterUrls',
  'enterUrls',
  'clickReviewButton',
  'confirmAndCreateJob',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.iwCreateJob.openMenu',
  clickIndexWorkerProject: 'tour.iwCreateJob.clickIndexWorkerProject',
  clickAddNewJob: 'tour.iwCreateJob.clickAddNewJob',
  enterJobName: 'tour.iwCreateJob.enterJobName',
  selectSearchConsoleProperty: 'tour.iwCreateJob.selectSearchConsoleProperty',
  selectHowToRegisterUrls: 'tour.iwCreateJob.selectHowToRegisterUrls',
  enterUrls: 'tour.iwCreateJob.enterUrls',
  clickReviewButton: 'tour.iwCreateJob.clickReviewButton',
  confirmAndCreateJob: 'tour.iwCreateJob.confirmAndCreateJob',
};
