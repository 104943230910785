import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconNotificationWithI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#EA4335" clipPath="url(#icon_-_notification_with_i_svg__a)">
      <path d="M19 14c1.38 0 2.56.49 3.54 1.46S24 17.61 24 19c0 1.39-.49 2.56-1.46 3.54-.97.97-2.15 1.46-3.54 1.46-1.39 0-2.56-.49-3.54-1.46C14.49 21.56 14 20.39 14 19c0-1.39.49-2.56 1.46-3.54.98-.98 2.15-1.46 3.54-1.46Zm.5 4h-1v4h1v-4Zm-.5-2c-.13 0-.25.05-.35.15a.485.485 0 0 0 0 .7.485.485 0 0 0 .7 0 .485.485 0 0 0 0-.7c-.1-.1-.22-.15-.35-.15ZM12.75 8.12h-1.5v4.9h1.5v-4.9ZM12 14.12c-.23 0-.42.08-.58.23-.16.15-.22.34-.22.58a.768.768 0 0 0 .8.8.8.8 0 0 0 .58-.23c.15-.15.22-.34.22-.57 0-.23-.07-.43-.22-.58a.783.783 0 0 0-.58-.23Z" />
      <path d="M7.75 17.2V9.95c0-1.18.41-2.19 1.24-3.01.82-.82 1.83-1.24 3.01-1.24 1.18 0 2.19.41 3.01 1.24.82.83 1.24 1.83 1.24 3.01v2.59c.48-.2.98-.34 1.5-.43V9.95c0-1.37-.42-2.57-1.26-3.61-.84-1.04-1.92-1.71-3.24-2.01v-.7c0-.35-.12-.65-.36-.89s-.54-.36-.89-.36-.65.12-.89.36-.36.54-.36.89v.7c-1.32.3-2.4.97-3.24 2.01-.84 1.04-1.26 2.25-1.26 3.61v7.25h-2v1.5h7.7c.02-.52.1-1.02.23-1.5H7.75ZM10.73 21.1c.35.35.77.52 1.27.52.15 0 .3-.02.43-.05-.22-.55-.37-1.14-.44-1.75H10.2c0 .5.17.92.53 1.28Z" />
    </g>
    <defs>
      <clipPath id="icon_-_notification_with_i_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconNotificationWithI;
