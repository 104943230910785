import {tableAnatomy as parts} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {defineMultiStyleConfig, definePartsStyle} =
  createMultiStyleConfigHelpers(parts.keys);

const amethyst = definePartsStyle({
  th: {
    fontWeight: 'normal',
    color: 'color.hambleA11y',
    textTransform: 'none',
    pl: 0,
    fontSize: 'memo1',
    borderBottom: '1px solid',
    borderColor: '#E4E4E4',
  },
  td: {
    fontSize: 'text2',
    color: 'color.regular',
    border: 'none !important',
    pl: 0,
    py: '8px',
  },
});

const comparative = definePartsStyle({
  th: {
    fontSize: 'text2',
    color: 'color.regular',
    textTransform: 'none',
    textAlign: 'center',
    borderBottom: '1px solid',
    borderBottomColor: '#828282',
    fontWeight: 'bold',
    '&:first-of-type': {
      textAlign: 'left',
      borderRight: '1px solid',
      borderRightColor: '#828282',
      fontWeight: 'normal',
    },
    p: 0,
    h: '50px',
  },
  td: {
    fontSize: 'text2',
    color: 'color.regular',
    textAlign: 'center',
    borderBottom: '1px solid',
    borderColor: '#E2E8F0',
    fontWeight: 'bold',
    '&:first-of-type': {
      textAlign: 'left',
      borderRight: '1px solid',
      borderRightColor: '#828282',
      fontWeight: 'normal',
    },
    p: 0,
    h: '50px',
  },
  tr: {
    '&:last-of-type': {
      '> td': {
        borderBottom: 'none',
      },
    },
  },
});

export const tableTheme = defineMultiStyleConfig({
  variants: {amethyst, comparative},
});
