export const colors = {
  brand: {
    50: '#C1C3E4',
    100: '#AFA9D8',
    200: '#A491CC',
    300: '#9D7AC0',
    400: '#9A63B3',
    500: '#9B4CA6',
    600: '#93418B',
    700: '#7F3769',
    800: '#6B2D4B',
    900: '#562331',
  },
  color: {
    navigation: '#000000',
    regular: '#565656',
    hambleA11y: '#767676',
    grayout: 'rgba(196, 197, 196, 1)',
    attention: '#EA4335',
    light: '#B9E2B0',
    positive: '#29B082',
    prior: '#1D865D',
    variation: '#296FDA',
    divider: '#E8EAED',
  },
  bgcolor: {
    versatile: '#F8F9FA',
  },
  white: '#FFFFFF',
  amethyst: {
    extraLight: '#F1E7F9',
    semiLight: '#DCC2E5',
    mediumLight: '#C598CE',
    light: '#B377BD',
    regular: '#9B4CA6',
    prior: '#84228F',
  },
  saMetrics: {
    clicks: '#296FDA',
    imps: '#9B4CA6',
    ctr: '#1D865D',
    rank: '#A6581D',
    queries: '#8A941D',
    urls: '#1D948D',
  },
  saMetricsLine: {
    clicks: '#4285F4',
    imps: '#9B4CA6',
    ctr: '#1D865D',
    rank: '#DD8500',
    queries: '#8A941D',
    urls: '#1D948D',
  },
  chart: {
    xAxisLineColor: '#E4E4E4',
    referenceLineColor: '#C4C4C4',
  },
};
