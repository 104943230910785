import {selectAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const amethyst = definePartsStyle({
  field: {
    fontSize: '13px',
    backgroundColor: 'white',
    border: '1px solid #d4c7d3',
    color: '#5F5F5F',
    cursor: 'pointer',
    _focus: {
      outlineColor: 'var(--chakra-ring-color)',
      outlineOffset: 0,
      outlineWidth: '3px',
    },
    _invalid: {
      borderBottom: '2px solid #ED4337',
    },
  },
  // icon component is
  icon: {
    fontSize: '13px',
    color: '#5F5F5F',
  },
});

export const selectTheme = defineMultiStyleConfig({
  variants: {
    amethyst,
  },
});
