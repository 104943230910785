import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconDash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#5F5F5F" clipPath="url(#icon_-_dash_svg__a)">
      <path d="M18.3 11H5.7v2h12.6v-2Z" />
      <path d="M5.7 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM18.3 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </g>
    <defs>
      <clipPath id="icon_-_dash_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconDash;
