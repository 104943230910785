import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconTodo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#C4C4C4"
      fillRule="evenodd"
      d="M18.672 17.413c.763-.44 1.339-1.444 1.328-2.326V6.74c0-.882-.675-1.864-1.439-2.305L11.356.323c-.753-.43-1.937-.43-2.69 0L1.44 4.424C.664 4.865.032 5.704.02 6.586L0 15.098c0 .87.576 1.896 1.34 2.326l7.337 4.245c.366.21.841.331 1.34.331.498 0 .985-.121 1.35-.33l7.305-4.257ZM2.114 16.08l7.338 4.245c.288.165.841.165 1.129 0l7.305-4.256c.254-.146.499-.54.553-.859a.735.735 0 0 0 .011-.122h-.01l.01-8.347c0-.342-.376-.805-.664-.97l-7.205-4.113c-.133-.078-.354-.122-.575-.122-.222 0-.443.044-.576.122L2.203 5.758c-.288.166-.653.662-.653.992v8.348c0 .33.276.816.564.98Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconTodo;
