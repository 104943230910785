import {
  PlanFragment,
  UsePlanOrgFragment,
  UsePlanOrgFragmentDoc,
} from 'lib/gql/generated';
import {useQueryParamOrgId} from 'features/org/store';
import {useFragment} from '@apollo/client';

/**
 * Subscription plan of current organization
 * @deprecated Declare the plan fragment for each component and fetch them on pages component.
 */
export const usePlan = (): {
  contract: UsePlanOrgFragment['contract'];
  plan: PlanFragment | null | undefined;
  planLabel: string;
} => {
  const {orgId} = useQueryParamOrgId();
  const {data, complete} = useFragment<UsePlanOrgFragment>({
    fragment: UsePlanOrgFragmentDoc,
    fragmentName: 'UsePlanOrg',
    from: {
      id: orgId,
      __typename: 'Organization',
    },
  });
  const contract = complete ? data.contract : null;
  const plan = contract?.subscriptionPlan;
  const planName = plan?.name;

  return {
    contract,
    plan: plan
      ? (Object.fromEntries(
          Object.entries(plan).map(([key, value]) => [
            key,
            value === -1 ? Infinity : value,
          ])
        ) as PlanFragment)
      : plan,
    planLabel: planName || '',
  };
};
