import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai';
import {GettingStartedTour} from 'lib/gql/generated';
import {useCallback} from 'react';

const runningTourAtom = atom<GettingStartedTour | undefined>(undefined);
export const useRunningTour = () => useAtomValue(runningTourAtom);
export const useIsTourRunning = (tour: GettingStartedTour) => {
  const [runningTour, setRunningTour] = useAtom(runningTourAtom);
  const isRunning = runningTour === tour;
  const setIsRunning = useCallback(
    (bool: boolean) => {
      setRunningTour(bool ? tour : undefined);
    },
    [setRunningTour, tour]
  );

  return [isRunning, setIsRunning] as const;
};
export const useStartTour = () => {
  const setRunningTour = useSetAtom(runningTourAtom);
  const setIndex = useSetAtom(indexAtom);
  return useCallback(
    (tour: GettingStartedTour, skipFirstStep: boolean) => {
      setRunningTour(tour);
      setIndex(skipFirstStep ? 1 : 0);
    },
    [setIndex, setRunningTour]
  );
};

const indexAtom = atom<number>(0);
export const useTourIndex = () => useAtom(indexAtom);

const abortTourAtom = atom(null, (_get, set) => {
  set(indexAtom, 0);
  set(runningTourAtom, undefined);
});

export const useAbortTour = () => useSetAtom(abortTourAtom);
