import {Box, Card} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';
import {UserMenu} from '../UserMenu';
import {NotificationLinkIcon} from './NotificationLinkIcon';
import {Logo} from './Logo';
import {useSideNav} from '../sideNav/store';
import {SideNavToggle} from '../sideNav/SideNavToggle';
import {GettingStartedLink} from './GettingStartedLink';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';
import {GettingStartedTour} from 'lib/gql/generated';
import {useRunningTour} from 'features/getting_started/tour/store/toursStore';
import {QuickStartGuideLink} from './QuickStartGuideLink';
import {SaExportStatusIcon} from 'features/search_analytics/raw_data_export/SaExportStatusIcon';

export const AppBar = () => {
  const {isOpen, contentWidth, contentLeft} = useSideNav();

  return (
    <AppBarPresentational
      showNavToggleButton={!isOpen}
      width={contentWidth}
      left={contentLeft}
    />
  );
};

export const AppBarPresentational = ({
  showNavToggleButton,
  width,
  left,
}: {
  showNavToggleButton: boolean;
  width: string;
  left: string;
}) => {
  const {t} = useTranslation();
  const runningTour = useRunningTour();

  return (
    <Card
      w={width}
      left={left}
      flexDir="row"
      as="header"
      aria-label={t('a11y.ヘッダー')}
      position="fixed"
      top="0"
      h="appBarHeight"
      alignItems="center"
      px={4}
      gap="small"
      borderRadius="none"
      boxShadow="0 1px 1px 0 rgba(0, 0, 0, 0.1)"
      zIndex="overlay"
      sx={{
        containerType: 'size',
        containerName: 'appBar',
      }}
    >
      {showNavToggleButton && (
        <TooltipWrapper tour={runningTour}>
          <SideNavToggle />
        </TooltipWrapper>
      )}
      <Box
        flexShrink={0}
        sx={{
          // center if appbar's width > 700px
          '@container appBar (min-width: 700px)': {
            position: 'fixed',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        <Logo />
      </Box>
      <Box flex="1" />
      <GettingStartedLink />
      <QuickStartGuideLink />
      <SaExportStatusIcon />
      <NotificationLinkIcon />
      <UserMenu />
    </Card>
  );
};

const TooltipWrapper = ({
  children,
  tour,
}: {
  children: React.ReactNode;
  tour: GettingStartedTour | undefined;
}) => {
  if (!tour) return <>{children}</>;
  return (
    <TourTooltip tour={tour} step="openMenu">
      {children}
    </TourTooltip>
  );
};
