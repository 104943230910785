import {
  GettingStartedTour,
  UserOrganizationRole,
  useSideNavigationQuery,
} from 'lib/gql/generated';
import {MAX_NAV_WIDTH, MIN_NAV_WIDTH, useSideNav} from './store';
import {Box, Button, Divider, Grid, Skeleton, Stack} from '@chakra-ui/react';
import {SideNavToggle} from './SideNavToggle';
import {OrgSwitcher} from '../OrgSwitcher';
import {
  MdAdd,
  MdCreditCard,
  MdDashboard,
  MdGroup,
  MdSettings,
} from 'react-icons/md';
import NextLink from 'next/link';
import {pagesPath} from 'lib/$path';
import {IndexWorkerProjects} from './IndexWorkerProjects';
import {useQueryParamOrgId} from 'features/org/store';
import {useRouter} from 'next/router';
import {SearchAnalyticsProjects} from './SearchAnalyticsProjects';
import {LangSwitcher} from '../LangSwitcher';
import {CreateProjectButton} from 'common/createProject/CreateProjectButton';
import {hasSufficientRole} from 'common/role/role';
import {useTranslation} from 'next-i18next';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {useRunningTour} from 'features/getting_started/tour/store/toursStore';
import {useCallback} from 'react';
import {UserAnalyticsProjects} from './UserAnalyticsProjects';

export const SideNavigation = () => {
  const {orgId, orgDatabaseId} = useQueryParamOrgId();
  const {data, loading} = useSideNavigationQuery({
    variables: {
      organizationId: orgId || '',
    },
    skip: !orgId,
  });
  const complete = !loading && !!data;

  const {isOpen, navWidth, setNavWidth} = useSideNav();
  const router = useRouter();
  const role = data?.viewerOrganizationRole ?? UserOrganizationRole.Guest; // fallback to Guest

  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});

  /** tour: Create Project **/
  const runningTour = useRunningTour();
  const {progress: progressIwPrj} = useTour({
    tour: GettingStartedTour.IwCreateProject,
    step: 'clickPlusButton',
  });
  const {progress: progressSaPrj} = useTour({
    tour: GettingStartedTour.SaCreateProject,
    step: 'clickPlusButton',
  });

  const CreateProjectTourTooltip = useCallback(
    (props: {children: React.ReactNode}) => {
      if (
        runningTour !== GettingStartedTour.IwCreateProject &&
        runningTour !== GettingStartedTour.SaCreateProject
      ) {
        return <>{props.children}</>;
      }
      return (
        <TourTooltip
          tour={runningTour}
          step="clickPlusButton"
          spanSxProps={{display: 'flex', flex: '1'}}
        >
          {props.children}
        </TourTooltip>
      );
    },
    [runningTour]
  );
  /** tour: Create Project **/

  /** tour: Search Console Property **/
  const {progress: progressIwScp} = useTour({
    tour: GettingStartedTour.IwRegisterProperty,
    step: 'clickMenu',
  });
  /** tour: Search Console Property **/

  if (!isOpen) {
    return null;
  }
  if (!orgDatabaseId) {
    return null;
  }
  if (router.pathname.includes('onboarding')) {
    return null;
  }

  return (
    <Stack
      w={navWidth}
      h="100%"
      maxH="100vh"
      justifyContent="space-between"
      p="4px"
      position="fixed"
      top="0"
      left="0"
      zIndex={1}
      boxShadow="md"
      spacing="small"
      background="white"
      sx={{
        'button, a, [role="button"]': {
          fontSize: 'memo1',
          svg: {
            fill: '#999',
          },
          "&[aria-current='true']": {
            background: 'amethyst.extraLight',
            fontWeight: 'bold',
            color: 'amethyst.regular',
            svg: {
              fill: 'amethyst.light',
            },
          },
        },
      }}
    >
      <Grid templateColumns={`${navWidth - 50}px 40px`} gap="4px">
        <Skeleton rounded="full" isLoaded={complete} display="flex" flex="1">
          <OrgSwitcher stretch />
        </Skeleton>
        <Skeleton rounded="full" isLoaded={complete}>
          <SideNavToggle />
        </Skeleton>
      </Grid>

      <Stack
        spacing="small"
        sx={{
          'button, a, [role="button"]': {
            justifyContent: 'flex-start',
          },
        }}
        overflowY="auto"
        flex="1"
        p="2px"
        m="-2px"
      >
        {/* Projects */}
        <Skeleton isLoaded={complete} display="flex" rounded="full">
          <CreateProjectTourTooltip>
            <CreateProjectButton
              variant="ghost"
              leftIcon={<MdAdd size="18px" />}
              size="sm"
              flexShrink={0}
              fontWeight="bold"
              flex="1"
              onClick={() => {
                if (runningTour === GettingStartedTour.IwCreateProject)
                  progressIwPrj();
                if (runningTour === GettingStartedTour.SaCreateProject)
                  progressSaPrj();
              }}
            >
              {t('createProject')}
            </CreateProjectButton>
          </CreateProjectTourTooltip>
        </Skeleton>

        <Divider />

        <Skeleton isLoaded={complete} display="flex" rounded="full">
          <NextLink
            legacyBehavior
            passHref
            href={pagesPath.orgs._orgId(orgDatabaseId).$url()}
          >
            <Button
              justifyContent="flex-start"
              as="a"
              size="sm"
              variant="ghost"
              leftIcon={<MdDashboard size="18px" />}
              flex="1"
              aria-current={
                router.pathname ===
                pagesPath.orgs._orgId(orgDatabaseId).$url().pathname
                  ? true
                  : undefined
              }
              flexShrink={0}
            >
              {t('dashboard')}
            </Button>
          </NextLink>
        </Skeleton>
        {complete && data.organization?.__typename === 'Organization' ? (
          <>
            <IndexWorkerProjects
              projects={data.organization.indexWorkerProjects}
            />
            <SearchAnalyticsProjects
              projects={data.organization.searchAnalyticsProjects}
            />
            <UserAnalyticsProjects
              projects={data.organization.userAnalyticsProjects}
            />
          </>
        ) : (
          <>
            <Skeleton rounded="16px" height="400px" />
          </>
        )}
      </Stack>

      <Divider />

      <Stack
        spacing="2px"
        sx={{
          'button, a, [role="button"]': {
            justifyContent: 'flex-start',
          },
        }}
        flexShrink={0}
        pb="regular"
      >
        {/* ユーザー: roleがfullMember以上 */}
        {hasSufficientRole(role, UserOrganizationRole.FullMember) && (
          <NextLink
            legacyBehavior
            passHref
            href={pagesPath.orgs._orgId(orgDatabaseId).permission.$url()}
          >
            <Button
              as="a"
              variant="ghost"
              leftIcon={<MdGroup size="18px" />}
              size="sm"
              aria-current={
                router.pathname ===
                pagesPath.orgs._orgId(orgDatabaseId).permission.$url().pathname
                  ? true
                  : undefined
              }
            >
              {t('userManagement')}
            </Button>
          </NextLink>
        )}

        {/* Search Console プロパティ */}
        <TourTooltip
          tour={GettingStartedTour.IwRegisterProperty}
          step="clickMenu"
          spanSxProps={{
            display: 'flex',
            flex: '1',
            overflow: 'hidden',
          }}
        >
          <Skeleton isLoaded={complete} display="flex" rounded="full">
            <NextLink
              legacyBehavior
              passHref
              href={pagesPath.orgs._orgId(orgDatabaseId).setting.$url()}
            >
              <Button
                as="a"
                variant="ghost"
                leftIcon={<MdSettings size="18px" />}
                size="sm"
                flex="1"
                aria-current={
                  router.pathname ===
                  pagesPath.orgs._orgId(orgDatabaseId).setting.$url().pathname
                    ? true
                    : undefined
                }
                onMouseDown={() => {
                  progressIwScp();
                }}
              >
                {t('searchConsoleProperties')}
              </Button>
            </NextLink>
          </Skeleton>
        </TourTooltip>

        {/* 支払い: Ownerのみ */}
        {hasSufficientRole(role, UserOrganizationRole.Owner) && (
          <NextLink
            legacyBehavior
            passHref
            href={pagesPath.orgs._orgId(orgDatabaseId).payment.$url()}
          >
            <Button
              as="a"
              variant="ghost"
              leftIcon={<MdCreditCard size="18px" />}
              size="sm"
              aria-current={
                router.pathname ===
                pagesPath.orgs._orgId(orgDatabaseId).payment.$url().pathname
                  ? true
                  : undefined
              }
            >
              {t('contracts')}
            </Button>
          </NextLink>
        )}

        {/* 言語 */}
        <Skeleton isLoaded={complete} display="flex" rounded="full">
          <LangSwitcher size="sm" stretch />
        </Skeleton>
      </Stack>

      <ResizeHandle
        onResize={e => {
          const newWidth = Math.min(
            Math.max(MIN_NAV_WIDTH, e.clientX),
            MAX_NAV_WIDTH
          );
          setNavWidth(newWidth);
        }}
      />
    </Stack>
  );
};

const ResizeHandle = ({onResize}: {onResize: (e: MouseEvent) => void}) => {
  return (
    <Box
      w="4px"
      h="100%"
      bg="transparent"
      cursor="ew-resize"
      position="absolute"
      right="-2px"
      top="0"
      _hover={{
        bg: 'amethyst.semiLight',
      }}
      _active={{
        bg: 'amethyst.light',
      }}
      transition="background-color 0.2s"
      onMouseDown={e => {
        e.preventDefault();
        window.addEventListener('mousemove', onResize);
        window.addEventListener('mouseup', () => {
          window.removeEventListener('mousemove', onResize);
        });
      }}
    ></Box>
  );
};
