import {Button, ButtonProps} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';
import {MdArrowBackIos} from 'react-icons/md';

export const BackButton = (props: ButtonProps) => {
  const {t} = useTranslation();
  const children = props.children ?? t('general.戻る');

  return (
    <Button
      variant="ghost"
      leftIcon={<MdArrowBackIos size="16px" />}
      {...props}
    >
      {children}
    </Button>
  );
};
