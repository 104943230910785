import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: '#565656',
  fontSize: 'clickable1',
  overflow: 'hidden',
});

const amethyst = defineStyle({
  fontWeight: 'bold',
  backgroundColor: 'amethyst.prior',
  color: 'white',
  height: '46px',
  transition: 'all .2s ease',
  _hover: {
    opacity: '.8',
    _disabled: {
      opacity: '.4',
      backgroundColor: 'amethyst.prior',
    },
  },
});

const amethystLight = defineStyle({
  fontWeight: 'bold',
  color: 'amethyst.regular',
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'amethyst.light',
  height: '46px',
  transition: 'all .2s ease',
  _hover: {
    backgroundColor: 'amethyst.extraLight',
  },
});

const text = defineStyle({
  fontWeight: 400,
  background: 'transparent',
  border: 'none',
  _hover: {
    textDecoration: 'underline',
    _disabled: {
      textDecoration: 'none',
    },
  },
  height: '46px',
});

const pill = defineStyle({
  height: '40px',
  fontWeight: 'normal',
  fontSize: 'text2',
  color: 'color.regular',
  background: '#F4F4F4',
  borderRadius: 'full',
  border: '1px solid',
  borderColor: '#C4C4C4',
  _hover: {
    background: '#D9D9D9',
    _disabled: {
      background: '#F4F4F4',
    },
  },
});

const pillAmethystLight = defineStyle({
  ...pill,
  background: 'white',
  borderColor: 'amelyst.light',
  color: 'amethyst.regular',
  _hover: {
    background: 'amethyst.extraLight',
    _disabled: {
      background: 'white',
    },
  },
});

const pillWhite = defineStyle({
  ...pill,
  background: 'white',
  borderColor: 'color.grayout',
  color: 'color.regular',
  _hover: {
    background: '#F4F4F4',
    _disabled: {
      background: 'white',
    },
  },
});

const danger = defineStyle({
  fontWeight: 'bold',
  color: 'color.attention',
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'color.attention',
  height: '46px',
  transition: 'all .2s ease',
  _hover: {
    backgroundColor: 'red.50',
  },
});

const menuButton = defineStyle({
  fontWeight: 'normal',
  background: 'white',
  fontSize: 'text2',
  height: '40px',
  padding: '8px 10px',
  border: '1px solid',
  borderColor: '#C4C4C4',
  _active: {
    outline: 'none',
    borderColor: 'amethyst.regular',
    borderWidth: '2px',
    padding: '7px 9px',
  },
  _focus: {
    outline: 'none',
    borderColor: 'amethyst.regular',
    borderWidth: '2px',
    padding: '7px 9px',
  },
  color: '#5F5F5F',
  textAlign: 'start',
});

const ghost = defineStyle({
  rounded: 'full',
  color: 'color.regular',
  background: 'transparent',
  fontWeight: 'normal',

  _hover: {
    background: '#F4F4F4',
    _disabled: {
      background: 'transparent',
    },
  },
  _active: {
    background: '#F4F4F4',
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: {
    amethyst,
    amethystLight,
    text,
    pill,
    pillAmethystLight,
    pillWhite,
    danger,
    menuButton,
    ghost,
  },
});
