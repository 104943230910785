import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconJobPreparing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon_-_job_preparing_svg__a)">
      <path
        fill="#565656"
        d="M4 20v-2h2.75l-.4-.35c-.87-.77-1.47-1.64-1.83-2.62-.35-.98-.52-1.97-.52-2.97 0-1.85.55-3.5 1.66-4.94C6.77 5.68 8.21 4.72 10 4.26v2.1c-1.2.43-2.17 1.17-2.9 2.21A5.93 5.93 0 0 0 6 12.06c0 .75.14 1.48.43 2.19.28.71.72 1.36 1.32 1.96l.25.25v-2.45h2v6H4V20Zm10-.25v-2.1c1.2-.43 2.17-1.17 2.9-2.21a5.93 5.93 0 0 0 1.1-3.49c0-.75-.14-1.48-.42-2.19-.28-.71-.73-1.36-1.33-1.96L16 7.55V10h-2V4h6v2h-2.75l.4.35c.82.82 1.41 1.7 1.79 2.66.37.96.56 1.94.56 2.94 0 1.85-.55 3.5-1.66 4.94-1.11 1.44-2.55 2.4-4.34 2.86Z"
      />
    </g>
    <defs>
      <clipPath id="icon_-_job_preparing_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconJobPreparing;
