import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconJobErrorOccured = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#565656" clipPath="url(#icon_-_job_error_occured_svg__a)">
      <path d="M11 21.73a9.88 9.88 0 0 1-3.54-1.04 9.832 9.832 0 0 1-2.85-2.18c-.81-.89-1.45-1.91-1.91-3.06-.47-1.15-.7-2.38-.7-3.68 0-1.52.3-2.92.91-4.2.61-1.28 1.44-2.38 2.49-3.3H3v-2h6v6H7V5.55c-.92.73-1.65 1.64-2.19 2.71S4 10.51 4 11.77c0 2.05.67 3.82 2.01 5.31 1.34 1.49 3 2.36 4.99 2.61V21.73Zm4-.45v-6h2v2.73c.92-.75 1.65-1.66 2.19-2.73s.81-2.23.81-3.5c0-2.05-.67-3.82-2.01-5.31-1.34-1.49-3-2.36-4.99-2.61V1.82c2.53.25 4.67 1.32 6.4 3.2C21.13 6.9 22 9.15 22 11.77c0 1.52-.3 2.92-.91 4.2-.61 1.28-1.44 2.38-2.49 3.3H21v2h-6v.01ZM12 17c-.28 0-.52-.1-.71-.29A.973.973 0 0 1 11 16c0-.28.1-.52.29-.71.19-.19.43-.29.71-.29.28 0 .52.1.71.29.19.19.29.43.29.71 0 .28-.1.52-.29.71-.19.19-.43.29-.71.29ZM13 7h-2v6h2V7Z" />
    </g>
    <defs>
      <clipPath id="icon_-_job_error_occured_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconJobErrorOccured;
