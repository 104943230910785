import {tagAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bgColor: 'bgcolor.versatile',
    fontSize: 'memo1',
    borderRadius: '4px',
    py: 0,
    px: '7px',
    border: '1px solid',
    borderColor: 'color.divider',
    color: 'color.regular',
  },
});

const middle = definePartsStyle({
  container: {
    backgroundColor: 'orange.400',
    fontWeight: 'bold',
    color: 'white',
    gap: '2px',
  },
});

const high = definePartsStyle({
  container: {
    backgroundColor: 'color.attention',
    color: 'white',
    fontWeight: 'bold',
    gap: '2px',
  },
});

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    middle,
    high,
  },
});
