import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M5.912 26.813c-.687 0-1.271-.241-1.753-.722a2.387 2.387 0 0 1-.721-1.754V8.662c0-.687.24-1.271.721-1.753a2.387 2.387 0 0 1 1.753-.721h21.175c.688 0 1.272.24 1.754.721.48.482.721 1.066.721 1.753v15.675c0 .688-.24 1.272-.721 1.754a2.386 2.386 0 0 1-1.754.721H5.913ZM16.5 17.255l-11-7.012v14.093c0 .115.04.213.121.293.08.08.177.12.291.12h21.175c.115 0 .213-.04.293-.12.08-.08.12-.178.12-.293V10.244l-11 7.012Zm0-2.131L27.294 8.25H5.706L16.5 15.125Zm-11-4.881V8.25v16.087c0 .115.04.213.121.293.08.08.177.12.291.12H5.5V10.244Z"
    />
  </svg>
);
export default SvgIconMail;
