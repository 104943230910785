import {Divider, HStack, Link, Stack, Text} from '@chakra-ui/react';
import {Env} from 'env/env';
import {useTranslation} from 'next-i18next';

export const AppFooter = () => {
  const {t} = useTranslation('layout');
  return (
    <Stack
      align="center"
      as="footer"
      aria-label={t('AppFooter.フッター')}
      width="100%"
      gap="15px"
      mb="10px"
    >
      <Divider width="100%" />
      <HStack align="center" gap="15px">
        {/* TODO: リンク先の指定 */}
        {/* <Link fontSize="memo1" color="navigation">
          {t('AppFooter.FAQ・お問い合わせ')}
        </Link> */}
        <Link
          fontSize="memo1"
          color="navigation"
          href={Env.LP_URL + 'tos'}
          isExternal
        >
          {t('AppFooter.利用規約')}
        </Link>
        <Link
          fontSize="memo1"
          color="navigation"
          href="https://ja.dev"
          isExternal
        >
          {t('AppFooter.運営会社')}
        </Link>
        <Link fontSize="memo1" color="navigation" href={Env.LP_URL} isExternal>
          {t('AppFooter.amethy.st')}
        </Link>
      </HStack>
      <Text as="small" fontSize="memo1" color="navigation">
        {t('AppFooter.©︎JADE K.K.')}
      </Text>
    </Stack>
  );
};
