import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconCheckedWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m9.552 18-5.7-5.7 1.425-1.425 4.275 4.275 9.175-9.175L20.152 7.4 9.552 18Z"
    />
  </svg>
);
export default SvgIconCheckedWhite;
