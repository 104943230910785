import NextLink from 'next/link';
import {Circle, Link, Text} from '@chakra-ui/react';
import {pagesPath} from 'lib/$path';
import {useQueryParamOrgId} from 'features/org/store';
import {
  GettingStartedOrganizationFragment,
  GettingStartedOrganizationFragmentDoc,
  GettingStartedTour,
} from 'lib/gql/generated';
import {useTranslation} from 'next-i18next';
import {MdAutoStories} from 'react-icons/md';
import {useFragment} from '@apollo/client';
import {useRouter} from 'next/router';

const tourCount = Object.keys(GettingStartedTour).length;

export const GettingStartedLink = () => {
  const {orgDatabaseId, orgId} = useQueryParamOrgId();
  const {data} = useFragment<GettingStartedOrganizationFragment>({
    fragment: GettingStartedOrganizationFragmentDoc,
    fragmentName: 'GettingStartedOrganization',
    from: {
      __typename: 'Organization',
      id: orgId || '',
    },
  });
  const loading = !data;

  const doneTours = data.doneGettingStartedTours ?? [];
  const allToursDone = doneTours.length >= tourCount;
  const {t} = useTranslation('layout');
  const router = useRouter();
  if (router.pathname === '/onboarding') return null;

  if (!orgDatabaseId) return null;
  if (loading) return null;
  if (allToursDone) return null;

  return (
    <NextLink
      href={pagesPath.orgs._orgId(orgDatabaseId).getting_started.$url()}
      passHref
      legacyBehavior
    >
      <Link
        fontWeight="bold"
        color="regular"
        position="relative"
        display="flex"
        gap="7px"
        alignItems="center"
      >
        <Circle
          position="absolute"
          top="-4px"
          right="-4px"
          size="6px"
          border="2px solid white"
          boxSizing="content-box"
          background="amethyst.prior"
        />
        <MdAutoStories fontSize="24px" />
        {
          <Text
            as="span"
            sx={{
              '@container appBar (max-width: 600px)': {
                display: 'none',
              },
            }}
          >
            {t('AppBar.gettingStarted')}
          </Text>
        }
      </Link>
    </NextLink>
  );
};
