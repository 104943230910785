import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {useTranslation} from 'next-i18next';

export const InvitationExpiredDialog = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation();
  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('NP-UPS.invitationExpired.招待 URL の有効期限が切れました')}
          <Divider mt="8px" borderWidth="2px" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            {t(
              'NP-UPS.invitationExpired.招待 URL の有効期限が切れました。ワークスペースのオーナーまたは管理者に再度招待を依頼してください。 '
            )}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
