import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#565656"
      d="M11.5 17h2v-6h-2v6Zm1-8a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13.5 8a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12.5 7a.967.967 0 0 0-.712.287A.968.968 0 0 0 11.5 8c0 .283.096.52.288.712A.965.965 0 0 0 12.5 9Zm0 13a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2.5 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12.5 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22.5 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137a9.733 9.733 0 0 1-3.9.788Zm0-2c2.233 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.442 2.325-5.675 0-2.233-.775-4.125-2.325-5.675C16.625 4.775 14.733 4 12.5 4c-2.233 0-4.125.775-5.675 2.325C5.275 7.875 4.5 9.767 4.5 12c0 2.233.775 4.125 2.325 5.675C8.375 19.225 10.267 20 12.5 20Z"
    />
  </svg>
);
export default SvgIconI;
