import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconIndexWorker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M13.236 2.32c-.69-.4-1.74-.4-2.43 0L4.274 6.04c-.7.4-1.29 1.3-1.3 2.1v7.58c0 .79.52 1.72 1.21 2.11l6.632 3.85c.33.19.76.3 1.21.3.45 0 .89-.11 1.22-.3l6.602-3.86c.68-.4 1.21-1.33 1.2-2.12V8.15c0-.8-.61-1.7-1.3-2.1l-6.512-3.73Zm-1.74 1.21L4.965 7.25c-.26.15-.59.545-.59.845v7.624c0 .3.25.74.51.89l6.631 3.85c.25.15.76.15 1.02 0l6.602-3.86c.23-.132.45-.49.5-.778V8.15c0-.31-.33-.74-.59-.89l-6.512-3.73c-.12-.07-.32-.11-.52-.11s-.4.04-.52.11ZM6.505 9.21v1.09h4.911V9.21H6.505Zm9.6 7.34.76-.76-1.42-1.42 1.42-1.42-.76-.76-1.42 1.42-1.42-1.42-.76.76 1.42 1.42-1.42 1.42.76.76 1.42-1.42 1.42 1.42Zm-2.839-7.86-.76.76 1.93 1.94 3.071-3.08-.76-.78-2.32 2.32-1.161-1.16Zm-6.761 5.13v1.09h4.911v-1.09H6.505Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconIndexWorker;
