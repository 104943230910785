import {UserOrganizationRole} from 'lib/gql/generated';

/**
 * Role utilities.
 * @module
 */

/**
 * Compare the user's role to the required role.
 */
export const hasSufficientRole = (
  userRole: UserOrganizationRole,
  requiredRole: UserOrganizationRole
) => {
  switch (requiredRole) {
    case UserOrganizationRole.Owner:
      return userRole === UserOrganizationRole.Owner;
    case UserOrganizationRole.Administrator:
      return (
        userRole === UserOrganizationRole.Owner ||
        userRole === UserOrganizationRole.Administrator
      );

    case UserOrganizationRole.FullMember:
      return (
        userRole === UserOrganizationRole.Owner ||
        userRole === UserOrganizationRole.Administrator ||
        userRole === UserOrganizationRole.FullMember
      );
    case UserOrganizationRole.RestrictedMember:
      return (
        userRole === UserOrganizationRole.Owner ||
        userRole === UserOrganizationRole.Administrator ||
        userRole === UserOrganizationRole.FullMember ||
        userRole === UserOrganizationRole.RestrictedMember
      );
    case UserOrganizationRole.Guest:
      return (
        userRole === UserOrganizationRole.Owner ||
        userRole === UserOrganizationRole.Administrator ||
        userRole === UserOrganizationRole.FullMember ||
        userRole === UserOrganizationRole.RestrictedMember ||
        userRole === UserOrganizationRole.Guest
      );
    default: {
      const exhaustiveCheck: never = requiredRole;
      throw new Error(`Unhandled role: ${exhaustiveCheck}`);
    }
  }
};
