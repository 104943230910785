import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconWarningPositive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon_-_warning_-_positive_svg__a)">
      <path fill="#29B082" d="M11 14V5h2v9h-2Zm0 5v-2h2v2h-2Z" />
    </g>
    <defs>
      <clipPath id="icon_-_warning_-_positive_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconWarningPositive;
