import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconContentCopyThin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="currentColor"
    {...props}
  >
    <path d="M5.75 21.808c-.506 0-.934-.175-1.284-.526A1.745 1.745 0 0 1 3.941 20V7.308h1.5V20a.29.29 0 0 0 .097.211.294.294 0 0 0 .211.097h9.692v1.5H5.75Zm3.5-3.5c-.506 0-.934-.175-1.284-.526a1.745 1.745 0 0 1-.525-1.282V5.115c0-.505.175-.932.525-1.282.35-.35.778-.525 1.283-.525h8.385c.505 0 .932.175 1.282.525.35.35.525.777.525 1.282V16.5c0 .505-.175.933-.525 1.282-.35.35-.777.526-1.282.526H9.249Zm0-1.5h8.384a.294.294 0 0 0 .211-.097.294.294 0 0 0 .096-.211V5.115a.294.294 0 0 0-.096-.211.294.294 0 0 0-.211-.096H9.249a.294.294 0 0 0-.211.096.294.294 0 0 0-.097.211V16.5a.29.29 0 0 0 .097.211.294.294 0 0 0 .211.097Z" />
  </svg>
);
export default SvgIconContentCopyThin;
