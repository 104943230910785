import {theme} from '@chakra-ui/react';

export const GoodColors = [
  theme.colors.blue['400'],
  theme.colors.green['400'],
  theme.colors.yellow['400'],
  theme.colors.orange['400'],
  theme.colors.red['400'],
  theme.colors.purple['400'],
  theme.colors.pink['400'],
  theme.colors.teal['400'],
] as const;

export const getRandomColor = () => {
  const index = Math.floor(Math.random() * GoodColors.length);
  return GoodColors[index] ?? GoodColors[0];
};
