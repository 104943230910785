import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.saCreateNavigationalQueryGroup'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickSearchAnalyticsProject',
  'clickQueryUrlGroup',
  'clickAdd',
  'enterQueryGroupName',
  'enterQuery',
  'clickReviewButton',
  'confirmAndCreateNavigationalQueryGroup',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.saCreateNavigationalQueryGroup.openMenu',
  clickSearchAnalyticsProject:
    'tour.saCreateNavigationalQueryGroup.clickSearchAnalyticsProject',
  clickQueryUrlGroup: 'tour.saCreateNavigationalQueryGroup.clickQueryUrlGroup',
  clickAdd: 'tour.saCreateNavigationalQueryGroup.clickAdd',
  enterQueryGroupName:
    'tour.saCreateNavigationalQueryGroup.enterQueryGroupName',
  enterQuery: 'tour.saCreateNavigationalQueryGroup.enterQuery',
  clickReviewButton: 'tour.saCreateNavigationalQueryGroup.clickReviewButton',
  confirmAndCreateNavigationalQueryGroup:
    'tour.saCreateNavigationalQueryGroup.confirmAndCreateNavigationalQueryGroup',
};
