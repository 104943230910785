import {IconButton, Tooltip} from '@chakra-ui/react';
import {useQueryParamOrgId} from 'features/org/store';
import {
  StripeSubscriptionStatus,
  useOrganizationBasicInfoQuery,
} from 'lib/gql/generated';
import {useTranslation} from 'next-i18next';
import {MdHelpOutline} from 'react-icons/md';

/**
 * Quick start guide link.
 * Only displayed in Japanese and when the subscription is active or trialing.
 */
export const QuickStartGuideLink = () => {
  const subscriptionStatus = useSubscriptionStatus();
  return (
    <QuickStartGuideLinkPresentation subscriptionStatus={subscriptionStatus} />
  );
};

const useSubscriptionStatus = () => {
  const {orgId} = useQueryParamOrgId();
  const {data} = useOrganizationBasicInfoQuery({
    variables: {
      id: orgId || '',
    },
    skip: !orgId,
    fetchPolicy: 'cache-only',
  });
  if (data?.node?.__typename !== 'Organization') return;
  return data.node.contract?.stripeSubscriptionStatus;
};

type PresentationProps = {
  subscriptionStatus: StripeSubscriptionStatus | undefined;
};
/**
 * Presentation component for the quick start guide link.
 */
export const QuickStartGuideLinkPresentation = ({
  subscriptionStatus,
}: PresentationProps) => {
  const {t, i18n} = useTranslation('layout');

  // Only display in Japanese
  if (i18n.language !== 'ja') return null;

  if (
    subscriptionStatus !== StripeSubscriptionStatus.Active &&
    subscriptionStatus !== StripeSubscriptionStatus.Trialing
  )
    return null;

  return (
    <Tooltip label={t('AppBar.quickStartGuide')}>
      <IconButton
        as="a"
        aria-label={t('AppBar.quickStartGuide')}
        icon={<MdHelpOutline fontSize="24px" />}
        variant="ghost"
        href="https://jadekk.notion.site/Amethyst-8d4ea5904ab9479eb749dc3009cb64a6"
        target="_blank"
        rel="noopener noreferrer"
      />
    </Tooltip>
  );
};
