import * as React from 'react';
import type {SVGProps} from 'react';
const SvgSkipNextFill0Wght300Grad0Opsz241 = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#565656"
      d="M16.386 17.308V6.692h1.5v10.616h-1.5Zm-10.269 0V6.692L14.08 12l-7.962 5.308Zm1.5-2.808 3.766-2.5-3.766-2.5v5Z"
    />
  </svg>
);
export default SvgSkipNextFill0Wght300Grad0Opsz241;
