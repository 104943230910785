import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {encodeId} from 'common/convertId';
import {useLocalStorageOrgId} from 'features/org/store';

/**
 * Save last org id to localStorage.
 */
export const useSaveLastOrgId = () => {
  const router = useRouter();
  const {setOrgId} = useLocalStorageOrgId();
  // 最後にみたワークスペースを記録しておく
  useEffect(() => {
    // Route change時にorgIdをlocalStorageに保存する
    const handleRouteChange = () => {
      const orgDatabaseId = router.query.orgId as string;
      if (orgDatabaseId) {
        const orgId = encodeId('Organization', 'customers', orgDatabaseId);
        setOrgId(orgId);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setOrgId]);
};
