import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconCheckedWithI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#29B082"
      d="m9.55 18-5.7-5.7 1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4 9.55 18ZM19 14c1.38 0 2.56.49 3.54 1.46S24 17.61 24 19c0 1.39-.49 2.56-1.46 3.54-.97.97-2.15 1.46-3.54 1.46-1.39 0-2.56-.49-3.54-1.46C14.49 21.56 14 20.39 14 19c0-1.39.49-2.56 1.46-3.54.98-.98 2.15-1.46 3.54-1.46Zm.5 4h-1v4h1v-4Zm-.5-2c-.13 0-.25.05-.35.15a.485.485 0 0 0 0 .7.485.485 0 0 0 .7 0 .485.485 0 0 0 0-.7c-.1-.1-.22-.15-.35-.15Z"
    />
  </svg>
);
export default SvgIconCheckedWithI;
