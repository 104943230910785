import {switchAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bg: 'color.grayout',
    _checked: {
      bg: 'amethyst.prior',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    color: 'color.regular',
    fontSize: 'text2',
    cursor: 'pointer',
  },
});

export const switchTheme = defineMultiStyleConfig({baseStyle});
