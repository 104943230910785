import {AppLayout} from 'common/app/AppLayout';
import {NextPageWithLayout} from 'common/types';
import {CompositeProvider} from 'common/CompositeProvider';
import {AppProps} from 'next/app';
import Head from 'next/head';
import {useTranslation} from 'next-i18next';
import TagManager from 'common/analytics/TagManager';
import {makeZodI18nMap} from 'zod-i18n-map';
import {z} from 'zod';
import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev';
import {Env} from 'env/env';
// import {DevTools} from 'jotai-devtools';

if (Env.ENV === 'local') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

/**
 * Register zod error messages to next-i18next.
 */
const useZodI18n = () => {
  // zodのエラーメッセージをi18nに対応させる
  const {t} = useTranslation();
  z.setErrorMap(makeZodI18nMap({t}));
};

export const App = ({
  Component,
  pageProps,
}: {
  Component: NextPageWithLayout;
  pageProps: AppProps['pageProps'];
}) => {
  const getLayout =
    Component.getLayout ?? (page => <AppLayout>{page}</AppLayout>);
  useZodI18n();

  return (
    <CompositeProvider>
      {/* <DevTools /> */}
      <TagManager />
      <Head>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <title>Amethyst</title>
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </CompositeProvider>
  );
};
