import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconErrorPastEm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#EA4335"
      d="M18.5 14c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5Zm1.49 6.9-2.14-2.14V15.6h1.3v2.63l1.75 1.75-.91.92ZM15.85 13.02l-.19-.19-.83-.83 4.88-4.88.71-.71-.71-.71-1.41-1.41-.71-.71-.71.71L12 9.17 7.12 4.29l-.71-.71-.71.71L4.29 5.7l-.71.71.71.71L9.17 12l-4.88 4.88-.71.71.71.71 1.41 1.41.71.71.71-.71L12 14.83l.83.83.19.19c.6-1.23 1.6-2.23 2.83-2.82v-.01Z"
    />
  </svg>
);
export default SvgIconErrorPastEm;
