import {FormControl, FormErrorMessage, useTheme} from '@chakra-ui/react';
import {ColorPicker} from 'common/color/ColorPicker';
import {useCreateProjectColor, useCreateProjectColorError} from './store';

export const CreateProjectColorInput = () => {
  const [color, setColor] = useCreateProjectColor();
  const error = useCreateProjectColorError();
  const theme = useTheme();

  return (
    <FormControl isInvalid={!!error}>
      <ColorPicker
        color={color || theme?.colors.amethyst.regular}
        onChange={color => setColor(color)}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
