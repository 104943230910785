import {accordionAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    color: 'color.regular',
  },
  container: {
    border: 'none',
    _last: {
      borderBottom: 'none',
    },
  },
  button: {
    _hover: {
      backgroundColor: '#F4F4F4',
    },
  },
});

const borderDash = definePartsStyle({
  container: {
    borderBottom: '1px dashed',
    borderBottomColor: 'color.divider',
    _last: {
      borderBottom: '1px dashed',
      borderBottomColor: 'color.divider',
    },
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    borderDash,
  },
});
