import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.iwCreateProject'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickPlusButton',
  'selectProjectTypeIndexWorker',
  'enterProjectName',
  'clickReviewButton',
  'confirmAndCreateProject',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.iwCreateProject.openMenu',
  clickPlusButton: 'tour.iwCreateProject.clickPlusButton',
  selectProjectTypeIndexWorker:
    'tour.iwCreateProject.selectProjectTypeIndexWorker',
  enterProjectName: 'tour.iwCreateProject.enterProjectName',
  clickReviewButton: 'tour.iwCreateProject.clickReviewButton',
  confirmAndCreateProject: 'tour.iwCreateProject.confirmAndCreateProject',
};
