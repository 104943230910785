import {FullScreenLoader} from 'common/FullScreenLoader';
import {decodeId} from 'common/convertId';
import {notifyError} from 'common/errorTracker/errrorTracker';
import {useQueryParamOrgId} from 'features/org/store';
import {pagesPath} from 'lib/$path';
import {
  StripeSubscriptionStatus,
  useOrganizationBasicInfoQuery,
} from 'lib/gql/generated';
import {useRouter} from 'next/router';

export const SubscriptionStatusBoundary = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const {orgId} = useQueryParamOrgId();
  const orgDatabaseId = orgId ? decodeId(orgId).docId : '';
  const {loading, data} = useOrganizationBasicInfoQuery({
    variables: {
      id: orgId || '',
    },
    fetchPolicy: 'network-only',
    skip:
      !orgId ||
      // show onboarding page even if subscription is not active
      router.pathname === pagesPath.onboarding.$url({query: {}}).pathname,
    onCompleted: data => {
      if (data.node?.__typename !== 'Organization') {
        notifyError(new Error('Organization not found'));
        return;
      }
      const subscriptionStatus = data.node.contract?.stripeSubscriptionStatus;
      if (
        subscriptionStatus === StripeSubscriptionStatus.Active ||
        subscriptionStatus === StripeSubscriptionStatus.Trialing
      ) {
        if (router.pathname === pagesPath.$url().pathname) {
          router.replace(pagesPath.orgs._orgId(orgDatabaseId).$url());
          return;
        }
        return;
      } else {
        router.replace(
          pagesPath.onboarding.$url({
            query: {
              onboardingStep: 'createContract',
              orgId: orgDatabaseId,
            },
          })
        );
      }
    },
  });

  if (pagesPath.onboarding.$url({query: {}}).pathname === router.pathname)
    return <>{children}</>;

  if (loading) return <FullScreenLoader />;

  if (data?.node?.__typename !== 'Organization') return null;
  if (
    data?.node?.contract?.stripeSubscriptionStatus !==
      StripeSubscriptionStatus.Active &&
    data?.node?.contract?.stripeSubscriptionStatus !==
      StripeSubscriptionStatus.Trialing
  )
    return null;

  return <>{children}</>;
};
