import {Box, Container} from '@chakra-ui/react';
import {AppBar} from 'common/app/appBar/AppBar';
import {ReactNode, useEffect} from 'react';
import {pagesPath} from 'lib/$path';
import {useRouter} from 'next/router';
import {
  useAppLayoutQuery,
  useUpdateUserMutation,
  useViewerQuery,
} from 'lib/gql/generated';
import {REDIRECT_TO_SESSION_STORAGE_KEY} from 'common/storage';
import {useTranslation} from 'next-i18next';
import {AppFooter} from './footer/AppFooter';
import {SideNavigation} from './sideNav/SideNavigation';
import {useSideNav} from './sideNav/store';
import {useMobile} from 'common/responsive/useMobile';
import {useSetErrorTrackerUser} from 'common/errorTracker/errrorTracker';
import {ServerError} from '@apollo/client';
import {useQueryParamOrgId} from 'features/org/store';
import {DemoAlert} from 'common/DemoAlert';
import {SubscriptionStatusBoundary} from './SubscriptionStatusBoundary';
import {Env} from 'env/env';
import {TermsOfServiceDialog} from './TermsOfServiceDialog';
import {InvitationExpiredDialog} from './InvitationExpiredDialog';
import {useSaveLastOrgId} from './useSaveLastOrgId';

/**
 * If the user is not logged in or the session has expired, redirect to the login screen.
 */
const useRedirect = () => {
  const router = useRouter();
  const {error} = useViewerQuery({
    errorPolicy: 'all',
    onCompleted: data => {
      if (data.viewer?.organizations.length === 0) {
        router.replace(
          pagesPath.onboarding.$url({
            query: {
              onboardingStep: 'registerOrganization',
            },
          })
        );
      }
    },
    onError: error => {
      if (Env.ENV === 'demo') {
        return;
      }

      if (
        error.networkError?.name === 'ServerParseError' ||
        error.networkError?.name === 'ServerError'
      ) {
        const statusCode = (error.networkError as ServerError)?.statusCode;
        if (statusCode === 401) {
          // If the user is not logged in or the session has expired, redirect to the login screen.
          if (
            router.asPath !== pagesPath.login.$url().pathname && // not on the login screen
            router.asPath !== pagesPath.login.callback.$url().pathname && // not on the login callback screen
            router.query.invitation_url_expired !== 'true' // not on the invitation URL expired screen
          ) {
            // Save the current path in sessionStorage in order to return to it after login.
            sessionStorage.setItem(
              REDIRECT_TO_SESSION_STORAGE_KEY,
              router.asPath
            );
            // Redirect to the login screen.
            router.push(pagesPath.login.$url());
          }
        }
      }
    },
  });

  return {
    // NOTE: 同意の403はuseRedirectでしかとれない（なぜかuseAppLayoutQueryでは取れない）のでここで判定する
    showAgreementDialog:
      (error?.networkError as ServerError)?.statusCode === 403,
  };
};

/**
 * Setup initial preferred language.
 */
const useInitialPreferredLanguage = () => {
  const {data} = useViewerQuery();
  const userId = data?.viewer?.id;
  const preferredLanguage = data?.viewer?.preferredLanguage;
  const {i18n} = useTranslation();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (!userId) return;
    if (preferredLanguage) return; // no need to update
    updateUser({
      variables: {
        input: {
          userID: userId,
          preferredLanguage: i18n.language,
        },
      },
    });
  }, [i18n.language, preferredLanguage, updateUser, userId]);
};

/**
 * App layout.
 * Setup error tracker, redirect, save last org id, and setup initial preferred language.
 */
export const AppLayout = ({children}: {children: ReactNode}) => {
  const {showAgreementDialog} = useRedirect();
  useSetErrorTrackerUser();
  useSaveLastOrgId();
  useInitialPreferredLanguage();

  const {orgId} = useQueryParamOrgId();

  const {data} = useAppLayoutQuery({
    variables: {
      organizationID: orgId || '',
      includeOrganization: !!orgId,
    },
    nextFetchPolicy: 'cache-only',
  });
  const {t} = useTranslation();
  const {isOpen, contentLeft, contentWidth} = useSideNav();
  const isMobile = useMobile();
  const router = useRouter();

  if (showAgreementDialog) {
    return <TermsOfServiceDialog />;
  }

  // 招待URLが期限切れの場合はエラーダイアログを表示する
  if (router.query.invitation_url_expired) {
    return (
      <InvitationExpiredDialog
        onClose={() => router.push(pagesPath.login.$url())}
      />
    );
  }

  // If '/' is accessed, show content.
  if (router.pathname === pagesPath.$url().pathname) {
    return <>{children}</>;
  }

  // If you are not logged in, do not display the main content (except for the `/` route)
  if (!data?.viewer) return null;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="absolute"
        minH="100vh"
        flexDir="column"
        ml={contentLeft}
        w={contentWidth}
      >
        <AppBar />
        <SubscriptionStatusBoundary>
          {isOpen && isMobile && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bg: 'rgba(0,0,0,0.5)',
                zIndex: 'overlay',
              }}
            />
          )}
          <Box zIndex="overlay" position="relative">
            <SideNavigation />
          </Box>
          <Container
            px="15px"
            maxW="100%"
            mt="70px"
            minH="calc(100vh - 70px)"
            pb={
              router.pathname ===
              pagesPath.orgs._orgId('').notification.$url({query: {}}).pathname
                ? 0
                : '50px'
            }
            as="main"
            aria-label={t('a11y.メインコンテンツ')}
          >
            <DemoAlert />
            {children}
          </Container>
          <AppFooter />
        </SubscriptionStatusBoundary>
      </Box>
    </>
  );
};
