import {
  ToastId,
  useToast as useChakraToast,
  UseToastOptions as ChakraToastOptions,
} from '@chakra-ui/react';
import {useCallback, useRef} from 'react';
import {useToastContent} from './useToastContent';
import {isApolloError} from '@apollo/client';
import {Operation, Resource} from './constants';

type ToastOptions = {
  resource: Resource;
  operation: Operation;
  error?: unknown;
  promise?: Promise<unknown>;
};

export const useToast = () => {
  const chakraToast = useChakraToast();
  const {getToastContent, loadingContent} = useToastContent();
  const idRef = useRef<ToastId>();

  const toast = useCallback(
    (options: ToastOptions) => {
      if (options.promise) {
        chakraToast.promise(options.promise, {
          success: {
            position: 'bottom',
            title: getToastContent({
              resource: options.resource,
              operation: options.operation,
            }),
          },
          error: e => ({
            position: 'bottom',
            title: getToastContent({
              resource: options.resource,
              operation: options.operation,
              isError: true,
              isNetworkError:
                e instanceof Error &&
                isApolloError(e) &&
                e.networkError !== null,
            }),
          }),
          loading: {
            position: 'bottom',
            title: loadingContent,
          },
        });
      } else {
        const content = getToastContent({
          resource: options.resource,
          operation: options.operation,
          isError: options.error !== undefined,
          isNetworkError:
            options.error instanceof Error &&
            isApolloError(options.error) &&
            options.error.networkError !== null,
        });
        const cto: ChakraToastOptions = {
          title: content,
          status: options.error ? 'error' : 'success',
          isClosable: true,
          position: 'bottom',
          duration: options.error ? null : 3000,
          onCloseComplete: () => {
            idRef.current = undefined;
          },
        };

        if (idRef.current) {
          chakraToast.update(idRef.current, cto);
        } else {
          idRef.current = chakraToast(cto);
        }
      }
    },
    [chakraToast, getToastContent, loadingContent]
  );

  return toast;
};
