import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#565656"
      d="m8.623 12 6-6 1.4 1.4-4.6 4.6 4.6 4.6-1.4 1.4-6-6Z"
    />
  </svg>
);
export default SvgIconExpand;
