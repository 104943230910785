import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconEditBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#565656" clipPath="url(#icon_-_edit_block_svg__a)">
      <path d="M20 3H4c-.28 0-.52.1-.71.29C3.1 3.48 3 3.72 3 4v5c0 .28.1.52.29.71.19.19.43.29.71.29h16c.28 0 .52-.1.71-.29.19-.19.29-.43.29-.71V4c0-.28-.1-.52-.29-.71A.973.973 0 0 0 20 3Zm-1 5H5V5h14v3ZM3.29 12.29c-.19.19-.29.43-.29.71v5c0 .28.1.52.29.71.19.19.43.29.71.29h4.43l2-2H5v-3h8.43l2-2H4c-.28 0-.52.1-.71.29ZM12 21v-2.12l5.3-5.3 2.12 2.12-5.3 5.3H12Zm8.12-6L18 12.88l.73-.73c.18-.18.42-.27.7-.27.28 0 .52.09.7.27l.73.73c.18.18.27.42.27.7 0 .28-.09.52-.27.7l-.73.73-.01-.01Z" />
    </g>
    <defs>
      <clipPath id="icon_-_edit_block_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconEditBlock;
