import {decodeId, encodeId} from 'common/convertId';
import {useAtom} from 'jotai/react';
import {atomWithStorage} from 'jotai/utils';
import {useRouter} from 'next/router';

const orgIdAtom = atomWithStorage<string | null>('orgId', null);
export const useLocalStorageOrgId = () => {
  const [orgId, setOrgId] = useAtom(orgIdAtom);
  const orgDatabaseId = orgId ? decodeId(orgId).docId : null;

  return {
    orgId,
    orgDatabaseId,
    setOrgId,
  };
};

export const useQueryParamOrgId = () => {
  const router = useRouter();
  if (typeof window === 'undefined') {
    return {
      orgId: null,
      orgDatabaseId: null,
    };
  }

  const orgDatabaseId =
    typeof router.query.orgId === 'string'
      ? router.query.orgId
      : Array.isArray(router.query.orgId)
      ? router.query.orgId[0] ?? null
      : null;

  const orgId = orgDatabaseId
    ? encodeId('Organization', 'customers', orgDatabaseId)
    : null;

  return {
    orgId,
    orgDatabaseId,
  };
};
