import {modalAnatomy as parts} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    pb: 0,
    color: 'color.regular',
    fontSize: 'heading2',
    textAlign: 'center',
  },
  body: {
    color: 'color.regular',
    fontSize: 'text2',
  },
  closeButton: {
    top: 4,
  },
});

export const modalTheme = defineMultiStyleConfig({baseStyle});
