import {ToastTranslationKey} from 'lib/i18n/type';

export const Resource = {
  ORGANIZATION: 'organization',
  PROJECT: 'project',
  INDEX_WORKER: 'index_worker',
  SEARCH_CONSOLE_PROPERTY: 'search_console_property',
  USER: 'user',
  PERMISSION: 'permission',
  SERVICE_ACCOUNT: 'service_account',
  NOTIFICATION: 'notification',
  VIEW: 'view',
  QUERY_GROUP: 'query_group',
  URL_GROUP: 'url_group',
  CONTRACT: 'contract',
  DASHBOARD: 'dashboard',
  NOTIFICATION_SETTING: 'notification_setting',
  BIGQUERY_INTEGRATION: 'bigquery_integration',
} as const;
export type Resource = (typeof Resource)[keyof typeof Resource];

export const ResourceI18n: Record<Resource, ToastTranslationKey> = {
  [Resource.ORGANIZATION]: 'ワークスペース',
  [Resource.PROJECT]: 'プロジェクト',
  [Resource.INDEX_WORKER]: 'Index Worker ジョブ',
  [Resource.SEARCH_CONSOLE_PROPERTY]: 'Search Console プロパティ',
  [Resource.USER]: 'ユーザー',
  [Resource.PERMISSION]: '権限',
  [Resource.SERVICE_ACCOUNT]: 'サービスアカウント',
  [Resource.NOTIFICATION]: '通知',
  [Resource.VIEW]: 'ビュー',
  [Resource.QUERY_GROUP]: 'クエリグループ',
  [Resource.URL_GROUP]: 'URL グループ',
  [Resource.CONTRACT]: '契約',
  [Resource.DASHBOARD]: 'ダッシュボード',
  [Resource.NOTIFICATION_SETTING]: 'お知らせ配信設定',
  [Resource.BIGQUERY_INTEGRATION]: 'BigQuery 連携情報',
};

export const Operation = {
  GET: 'get',
  CREATE: 'create',
  REGISTER: 'register',
  UNREGISTER: 'unregister',
  UPDATE: 'update',
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
  SEND_INVITATION: 'send_invitation',
  CHANGE: 'change',
  MOVE: 'move',
  LOGIN: 'login',
  SEND_RESET_PASSWORD_EMAIL: 'send_reset_password_email',
  RESET_PASSWORD: 'reset_password',
} as const;
export type Operation = (typeof Operation)[keyof typeof Operation];

export const OperationI18n: Record<Operation, ToastTranslationKey> = {
  [Operation.GET]: '{{resource}} を取得しました',
  [Operation.CREATE]: '{{resource}} を作成しました',
  [Operation.REGISTER]: '{{resource}} を登録しました',
  [Operation.UNREGISTER]: '{{resource}} を登録解除しました',
  [Operation.UPDATE]: '{{resource}} を更新しました',
  [Operation.DUPLICATE]: '{{resource}} を複製しました',
  [Operation.DELETE]: '{{resource}} を削除しました',
  [Operation.MOVE]: '{{resource}} を移動しました',
  [Operation.ARCHIVE]: '{{resource}} をアーカイブしました',
  [Operation.UNARCHIVE]: '{{resource}} をアーカイブ解除しました',
  [Operation.SEND_INVITATION]: '招待メールを送信しました',
  [Operation.CHANGE]: '{{resource}} を変更しました',
  [Operation.LOGIN]: 'ログインしました',
  [Operation.SEND_RESET_PASSWORD_EMAIL]:
    'パスワードリセット用のメールを送信しました',
  [Operation.RESET_PASSWORD]:
    'パスワードをリセットしました。ログインしてください',
};

export const OperationFailI18n: Record<Operation, ToastTranslationKey> = {
  [Operation.GET]: '{{resource}} の取得に失敗しました',
  [Operation.CREATE]: '{{resource}} の作成に失敗しました',
  [Operation.REGISTER]: '{{resource}} の登録に失敗しました',
  [Operation.UNREGISTER]: '{{resource}} の登録解除に失敗しました',
  [Operation.UPDATE]: '{{resource}} の更新に失敗しました',
  [Operation.DUPLICATE]: '{{resource}} の複製に失敗しました',
  [Operation.DELETE]: '{{resource}} の削除に失敗しました',
  [Operation.MOVE]: '{{resource}} の移動に失敗しました',
  [Operation.ARCHIVE]: '{{resource}} のアーカイブに失敗しました',
  [Operation.UNARCHIVE]: '{{resource}} のアーカイブ解除に失敗しました',
  [Operation.SEND_INVITATION]: '招待メールの送信に失敗しました',
  [Operation.CHANGE]: '{{resource}} の変更に失敗しました',
  [Operation.LOGIN]: 'ログインに失敗しました',
  [Operation.SEND_RESET_PASSWORD_EMAIL]:
    'パスワードリセット用のメールの送信に失敗しました',
  [Operation.RESET_PASSWORD]: 'パスワードのリセットに失敗しました',
};
