import {tabsAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tab: {
    _selected: {
      color: 'amethyst.prior',
      // fontWeight: 'bold',
    },
    _active: {
      background: 'transparent',
    },
    _hover: {
      background: '#F4F4F4',
    },
  },
  root: {
    borderColor: 'color.divider',
    width: '100%',
    maxWidth: '100%',
    color: 'color.regular',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
  },
  tabpanel: {
    px: 0,
    py: 1,
  },
});

const amethyst = definePartsStyle({
  tablist: {
    borderBottom: '1px solid',
    borderColor: 'color.divider',
  },
  tab: {
    _selected: {
      mb: '-2px',
      borderBottom: '2px solid',
      borderColor: 'amethyst.prior',
    },
  },
});

const amethystVertical = definePartsStyle({
  tablist: {
    borderRight: '1px solid',
    borderColor: 'color.divider',
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
    fontSize: '8px',
    _selected: {
      mr: '-2px',
      borderRight: '2px solid',
      borderColor: 'amethyst.prior',
    },
  },
});

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    amethyst,
    amethystVertical,
  },
});
