import {popoverAnatomy as parts} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  content: {
    rounded: 'none',
    border: 'none',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  },
  body: {
    px: '15px',
    py: '18px',
    color: 'color.regular',
    fontSize: 'text2',
    whiteSpace: 'pre-wrap',
  },
});

export const popoverTheme = defineMultiStyleConfig({
  baseStyle,
});
