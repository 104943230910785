import {GettingStartedTour} from 'lib/gql/generated';
import * as IwCreateJob from './iwCreateJob';
import * as IwCreateProject from './iwCreateProject';
import * as IwRegisterProperty from './iwRegisterProperty';
import * as SaCreateNavigationalQueryGroup from './saCreateNavigationalQueryGroup';
import * as SaCreateProject from './saCreateProject';
import * as SaCreateUrlGroup from './saCreateUrlGroup';
import * as SaCreateView from './saCreateView';
import * as SaSearchConsoleIntegration from './saSearchConsoleIntegration';

export const Steps = {
  [GettingStartedTour.IwCreateJob]: IwCreateJob.Steps,
  [GettingStartedTour.IwCreateProject]: IwCreateProject.Steps,
  [GettingStartedTour.IwRegisterProperty]: IwRegisterProperty.Steps,
  [GettingStartedTour.SaCreateNavigationalQueryGroup]:
    SaCreateNavigationalQueryGroup.Steps,
  [GettingStartedTour.SaCreateProject]: SaCreateProject.Steps,
  [GettingStartedTour.SaCreateUrlGroup]: SaCreateUrlGroup.Steps,
  [GettingStartedTour.SaCreateView]: SaCreateView.Steps,
  [GettingStartedTour.SaSearchConsoleIntegration]:
    SaSearchConsoleIntegration.Steps,
};
export type StepType<T extends GettingStartedTour> = (typeof Steps)[T][number];

export const Translations = {
  [GettingStartedTour.IwCreateJob]: IwCreateJob.Translations,
  [GettingStartedTour.IwCreateProject]: IwCreateProject.Translations,
  [GettingStartedTour.IwRegisterProperty]: IwRegisterProperty.Translations,
  [GettingStartedTour.SaCreateNavigationalQueryGroup]:
    SaCreateNavigationalQueryGroup.Translations,
  [GettingStartedTour.SaCreateProject]: SaCreateProject.Translations,
  [GettingStartedTour.SaCreateUrlGroup]: SaCreateUrlGroup.Translations,
  [GettingStartedTour.SaCreateView]: SaCreateView.Translations,
  [GettingStartedTour.SaSearchConsoleIntegration]:
    SaSearchConsoleIntegration.Translations,
};
