import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#5F5F5F"
      d="M8.5 20.5V19h2v-2H4.3c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 2.5 15.2V5.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h15.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v9.9c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525h-6.2v2h2v1.5h-7Zm-4.2-5h15.4c.067 0 .133-.033.2-.1s.1-.133.1-.2V5.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H4.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v9.9c0 .067.033.133.1.2s.133.1.2.1Zm-.3 0V5v10.5Z"
    />
  </svg>
);
export default SvgIconDesktop;
