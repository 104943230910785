import {
  GettingStartedOrganizationFragment,
  GettingStartedOrganizationFragmentDoc,
  GettingStartedTour,
  useMarkGettingStartedTourAsDoneMutation,
} from 'lib/gql/generated';
import {useIsTourRunning, useTourIndex} from '../store/toursStore';
import {useCallback, useRef} from 'react';
import {useQueryParamOrgId} from 'features/org/store';
import {StepType, Steps} from '../tours';
import {useTourCompletedToast} from './useTourCompletedToast';
import {useFragment} from '@apollo/client';

const TIMEOUT = 400;

type UseTourOptions<T extends GettingStartedTour> = {
  tour: T;
  step: StepType<T>;
};

export const useTour = <T extends GettingStartedTour>({
  tour,
  step,
}: UseTourOptions<T>) => {
  // Whether the tour is running or not
  const [isRunning, setIsRunning] = useIsTourRunning(tour);
  const [index, setIndex] = useTourIndex();

  const steps = Steps[tour];
  const lastStep = steps.length > 0 ? steps[steps.length - 1] : undefined;
  const current = typeof index === 'number' ? steps?.[index] : undefined;

  const {orgId} = useQueryParamOrgId();
  const {data} = useFragment<GettingStartedOrganizationFragment>({
    fragment: GettingStartedOrganizationFragmentDoc,
    fragmentName: 'GettingStartedOrganization',
    from: {
      __typename: 'Organization',
      id: orgId || '',
    },
  });
  const [markAsDone] = useMarkGettingStartedTourAsDoneMutation();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const {showTourCompletedToast} = useTourCompletedToast();

  const handleProgress = useCallback(() => {
    if (!data.doneGettingStartedTours) return;
    // If the step is the last step, mark the tour as done
    // Even if the tour is not running, we still want to mark it as done
    // If the tour is already marked as done, we don't want to mark it again
    if (
      orgId &&
      step === lastStep &&
      !data.doneGettingStartedTours.includes(tour)
    ) {
      return markAsDone({
        variables: {
          input: {
            organizationID: orgId,
            step: tour,
          },
        },
      }).then(res => {
        // Show the tour completed toast
        showTourCompletedToast();
        return res;
      });
    }

    if (!isRunning) return Promise.resolve();
    if (!setIndex) return Promise.resolve();

    if (step === undefined) return Promise.resolve();
    if (step !== current) return Promise.resolve();

    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // delay the progress to the next step
    // so that the ui updates before the next tooltip is shown
    timeoutRef.current = setTimeout(() => {
      if (current === lastStep) {
        // final step
        setIsRunning(false);
        setIndex(0);
        return;
      } else {
        setIndex(current => current + 1);
      }
    }, TIMEOUT);
    return Promise.resolve();
  }, [
    current,
    data,
    isRunning,
    lastStep,
    markAsDone,
    orgId,
    setIndex,
    setIsRunning,
    showTourCompletedToast,
    step,
    tour,
  ]);

  return {
    run: isRunning,
    current: current,
    progress: handleProgress,
  };
};
