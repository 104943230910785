import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.saCreateView'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickSearchAnalyticsProject',
  'clickAll',
  'selectNavigationalQueryGroup',
  'clickApply',
  'clickSaveView',
  'enterViewName',
  'clickReviewButton',
  'clickConfirm',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.saCreateView.openMenu',
  clickSearchAnalyticsProject: 'tour.saCreateView.clickSearchAnalyticsProject',
  clickAll: 'tour.saCreateView.clickAll',
  selectNavigationalQueryGroup:
    'tour.saCreateView.selectNavigationalQueryGroup',
  clickApply: 'tour.saCreateView.clickApply',
  clickSaveView: 'tour.saCreateView.clickSaveView',
  enterViewName: 'tour.saCreateView.enterViewName',
  clickReviewButton: 'tour.saCreateView.clickReviewButton',
  clickConfirm: 'tour.saCreateView.clickConfirm',
};
