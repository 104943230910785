import {Button, ButtonProps, useDisclosure} from '@chakra-ui/react';
import {useOrg} from 'common/useOrg';
import {MouseEventHandler, forwardRef, useCallback} from 'react';
import {CreateProjectModal} from './CreateProjectModal';
import {useInitCreateProject} from './store';

const useCreateProject = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const initOrg = useInitCreateProject();
  const {currentOrgId, defaultOrgId} = useOrg();
  const orgIdInit = currentOrgId || defaultOrgId;

  const handleOpen = useCallback(() => {
    if (!orgIdInit) return;
    initOrg(orgIdInit);
    onOpen();
  }, [initOrg, onOpen, orgIdInit]);

  const modal = <CreateProjectModal open={isOpen} onClose={onClose} />;

  return {handleOpen, modal};
};

export const CreateProjectButton = forwardRef<
  HTMLButtonElement,
  // Omit<ButtonProps, 'onClick'>
  ButtonProps
>(({onClick, ...rest}, ref) => {
  const {handleOpen, modal} = useCreateProject();
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      handleOpen();
      onClick?.(e);
    },
    [handleOpen, onClick]
  );

  return (
    <>
      <Button {...rest} onClick={handleClick} ref={ref} />
      {modal}
    </>
  );
});

CreateProjectButton.displayName = 'CreateProjectButton';
