export const TOURMALINE_TOKEN_LOCAL_STORAGE_KEY = 'tourmaline_token';

export const REDIRECT_TO_SESSION_STORAGE_KEY = 'redirect_to';

export const LOCALE_COOKIE_NAME = 'NEXT_LOCALE';
const LOCALE_COOKIE_PATH = '/';
const LOCALE_COOKIE_MAX_AGE = 60 * 60 * 24 * 365; // 1 year

export const LOCALE_COOKIES = {
  en: `${LOCALE_COOKIE_NAME}=en; path=${LOCALE_COOKIE_PATH}; max-age=${LOCALE_COOKIE_MAX_AGE}`,
  ja: `${LOCALE_COOKIE_NAME}=ja; path=${LOCALE_COOKIE_PATH}; max-age=${LOCALE_COOKIE_MAX_AGE}`,
} as const;
