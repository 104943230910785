import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.saCreateProject'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickPlusButton',
  'selectProjectTypeSearchAnalytics',
  'enterProjectName',
  'clickReviewButton',
  'confirmAndCreateProject',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.saCreateProject.openMenu',
  clickPlusButton: 'tour.saCreateProject.clickPlusButton',
  selectProjectTypeSearchAnalytics:
    'tour.saCreateProject.selectProjectTypeSearchAnalytics',
  enterProjectName: 'tour.saCreateProject.enterProjectName',
  clickReviewButton: 'tour.saCreateProject.clickReviewButton',
  confirmAndCreateProject: 'tour.saCreateProject.confirmAndCreateProject',
};
