import {TourTranslationKey} from 'lib/i18n/type';
import {StripPrefixFromUnionKeys} from 'common/types';

export type Step = StripPrefixFromUnionKeys<
  TourTranslationKey,
  'tour.iwRegisterPropety'
>;

export const Steps: Step[] = [
  'openMenu',
  'clickMenu',
  'clickCreateButton',
  'selectProperty',
  'confirmProperty',
  'clickRegisterButton',
];

export const Translations: Record<Step, TourTranslationKey> = {
  openMenu: 'tour.iwRegisterPropety.openMenu',
  clickMenu: 'tour.iwRegisterPropety.clickMenu',
  clickCreateButton: 'tour.iwRegisterPropety.clickCreateButton',
  selectProperty: 'tour.iwRegisterPropety.selectProperty',
  confirmProperty: 'tour.iwRegisterPropety.confirmProperty',
  clickRegisterButton: 'tour.iwRegisterPropety.clickRegisterButton',
};
