import {Alert, Box, Button, Center, Checkbox, Stack} from '@chakra-ui/react';
import {Dialog} from 'common/Dialog';
import {pagesPath} from 'lib/$path';
import {useRouter} from 'next/router';
import {useState, useCallback} from 'react';
import {useTranslation} from 'next-i18next';
import {notifyError} from 'common/errorTracker/errrorTracker';
import {TOURMALINE_TOKEN_LOCAL_STORAGE_KEY} from 'common/storage';
import {Env} from 'env/env';

/**
 * Dialog to agree to the terms of service.
 */
export const TermsOfServiceDialog = () => {
  const router = useRouter();
  const handleClose = () => {
    router.push(pagesPath.login.$url());
  };
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [agreeTos, {loading, error}] = useAgreeTos();
  const {
    t,
    i18n: {language},
  } = useTranslation();

  return (
    <Dialog
      title={t('TO_O_NNN_termsOfUse.利用規約に同意する')}
      isOpen
      onClose={handleClose}
      closeOnOverlayClick={false}
    >
      <Stack gap="regular" mt="regular">
        <Box
          as="iframe"
          border="1px solid #E2E8F0"
          height="360px"
          p="regular"
          src={`${Env.LP_URL}${language === 'ja' ? '/ja' : ''}/tos_plain`}
        />
        <Center>
          <Checkbox
            checked={isTermsChecked}
            onChange={e => setIsTermsChecked(e.target.checked)}
          >
            {t('TO_O_NNN_termsOfUse.利用規約に同意する')}
          </Checkbox>
        </Center>
        <Center gap="regular">
          <Button variant="amethystLight" onClick={handleClose}>
            {t('TO_O_NNN_termsOfUse.同意しない')}
          </Button>
          <Button
            isDisabled={!isTermsChecked}
            variant="amethyst"
            onClick={agreeTos}
            isLoading={loading}
          >
            {t('TO_O_NNN_termsOfUse.続ける')}
          </Button>
        </Center>
        {error && (
          <Alert status="error">
            {t('TO_O_NNN_termsOfUse.利用規約への同意に失敗しました')}
          </Alert>
        )}
      </Stack>
    </Dialog>
  );
};

/**
 * Logic to agree to the terms of service.
 */
const useAgreeTos = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const router = useRouter();

  const agreeTos = useCallback(async () => {
    setLoading(true);

    const token = localStorage.getItem(TOURMALINE_TOKEN_LOCAL_STORAGE_KEY);
    if (!token) {
      notifyError(new Error('Token is not found.'));
      setError(true);
      setLoading(false);
      return;
    }

    // token is required both in the body and in the header
    const formData = new FormData();
    formData.append('token', token);
    fetch(`${Env.TOURMALINE_ENDPOINT}update_term_version`, {
      method: 'POST',
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
      redirect: 'manual',
    })
      .then(() => {
        router.reload();
        // after reload, the user will be redirected to the onboarding page or the dashboard page
      })
      .catch(e => {
        console.error(e);
        notifyError(e as Error);
        setError(true);
        setLoading(false);
      });
  }, [router]);

  return [agreeTos, {loading, error}] as const;
};
