import {Box, Link} from '@chakra-ui/react';
import {Env} from 'env/env';
import {useTranslation} from 'next-i18next';

export const DemoAlert = () => {
  if (Env.ENV !== 'demo') return null;

  return <DemoAlertPresentation />;
};

export const DemoAlertPresentation = () => {
  const {t, i18n} = useTranslation();
  const language = i18n.language === 'ja' ? 'ja' : '';

  return (
    <Box
      background="amethyst.extraLight"
      color="amethyst.prior"
      p={4}
      mb={4}
      rounded="md"
      fontSize="sm"
      whiteSpace="pre-wrap"
    >
      {t(
        'demo.これはデモ版です。データ取得系の操作のみ可能です。\n製品の詳細は、'
      )}
      <Link
        href={'https://amethy.st/' + language}
        color="color.variation"
        isExternal
      >
        {t('demo.公式サイト')}
      </Link>
      {t('demo.をご覧ください。')}
    </Box>
  );
};
