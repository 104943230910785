import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconDeleteMember = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#565656"
      d="M20.275 22.625 17.65 20H4.5v-2.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 0 1 6.1 14.55a13.7 13.7 0 0 1 2.288-.925 16.378 16.378 0 0 1 2.362-.525L1.875 4.225 3.3 2.8l18.4 18.4-1.425 1.425ZM6.5 18h9.15l-3-3h-.15c-.933 0-1.858.112-2.775.337A13.06 13.06 0 0 0 7 16.35a.97.97 0 0 0-.5.85v.8Zm12.4-3.45c.483.233.867.588 1.15 1.063.283.475.433.987.45 1.537l-3.35-3.35c.3.117.596.233.888.35.291.117.579.25.862.4Zm-4.2-3.2-1.475-1.475c.383-.15.692-.396.925-.738.233-.341.35-.72.35-1.137 0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 12.5 6a1.97 1.97 0 0 0-1.137.35 1.961 1.961 0 0 0-.738.925L9.15 5.8a4.085 4.085 0 0 1 1.45-1.325A3.92 3.92 0 0 1 12.5 4c1.1 0 2.042.392 2.825 1.175C16.108 5.958 16.5 6.9 16.5 8c0 .683-.158 1.317-.475 1.9a4.085 4.085 0 0 1-1.325 1.45Z"
    />
  </svg>
);
export default SvgIconDeleteMember;
