import {
  Box,
  FormControl,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import {useCreateProjectKind, useCreateProjectKindError} from './store';
import {useTranslation} from 'next-i18next';
import {GettingStartedTour, ProjectKind} from 'lib/gql/generated';
import {useTour} from 'features/getting_started/tour/hook/useTour';
import {TourTooltip} from 'features/getting_started/tour/components/TourTooltip';
import {useRunningTour} from 'features/getting_started/tour/store/toursStore';
import {
  IconIndexWorker,
  IconSearchAnalytics,
  IconUserAnalytics,
} from 'common/icons';
import {MdArrowDropDown} from 'react-icons/md';
import {match} from 'ts-pattern';
import {Portal} from '../Portal';
import {BetaFeatureTag} from 'common/BetaFeatureTag';

const Options = [
  '',
  ProjectKind.IndexWorker,
  ProjectKind.SearchAnalytics,
  ProjectKind.UserAnalytics,
] as const;

export const CreateProjectKindSelect = () => {
  // i18n
  const {t} = useTranslation('common');
  const {t: errorMessage} = useTranslation('zod');

  // tour
  const handleTourProgress = useHandleTourProgress();

  // state
  const [kind, setKind] = useCreateProjectKind();
  const error = useCreateProjectKindError();

  // handler
  const handleChange = (kind: ProjectKind | '') => {
    setKind(kind);
    handleTourProgress(kind);
  };

  // get label
  const getLabel = (kind: ProjectKind | '') => {
    return match(kind)
      .with(ProjectKind.IndexWorker, () => t('projectKind.INDEX_WORKER'))
      .with(ProjectKind.SearchAnalytics, () =>
        t('projectKind.SEARCH_ANALYTICS')
      )
      .with(ProjectKind.UserAnalytics, () => t('projectKind.USER_ANALYTICS'))
      .with('', () => t('CreateProjectKindSelect.selectLabel'))
      .exhaustive();
  };

  // get icon
  const getIcon = (kind: ProjectKind | ''): React.ReactElement | undefined => {
    return match(kind)
      .with(ProjectKind.IndexWorker, () => <IconIndexWorker />)
      .with(ProjectKind.SearchAnalytics, () => <IconSearchAnalytics />)
      .with(ProjectKind.UserAnalytics, () => <IconUserAnalytics />)
      .with('', () => undefined)
      .exhaustive();
  };

  // getDescription
  const getDescription = (kind: ProjectKind | '') => {
    return match(kind)
      .with(ProjectKind.IndexWorker, () =>
        t('CreateProjectKindSelect.description.INDEX_WORKER')
      )
      .with(ProjectKind.SearchAnalytics, () =>
        t('CreateProjectKindSelect.description.SEARCH_ANALYTICS')
      )
      .with(ProjectKind.UserAnalytics, () =>
        t('CreateProjectKindSelect.description.USER_ANALYTICS')
      )
      .with('', () => undefined)
      .exhaustive();
  };

  return (
    <FormControl isInvalid={!!error}>
      <TourTooltipWrapper>
        <Menu>
          <MenuButton
            as={Button}
            variant="menuButton"
            rightIcon={<MdArrowDropDown />}
          >
            {getLabel(kind)}
          </MenuButton>

          <Portal>
            <MenuList w="360px">
              {Options.map(kind => (
                <KindMenuItem
                  key={kind}
                  kind={kind}
                  handleChange={handleChange}
                  icon={getIcon(kind)}
                  label={getLabel(kind)}
                  description={getDescription(kind)}
                  isBeta={kind === ProjectKind.UserAnalytics}
                />
              ))}
            </MenuList>
          </Portal>
        </Menu>
      </TourTooltipWrapper>
      <FormErrorMessage>
        {errorMessage('originalErrors.need_to_select')}
      </FormErrorMessage>
    </FormControl>
  );
};

const KindMenuItem = ({
  kind,
  handleChange,
  icon,
  label,
  description,
  isBeta,
}: {
  kind: ProjectKind | '';
  handleChange: (kind: ProjectKind | '') => void;
  icon?: React.ReactElement;
  label: string;
  description?: string;
  isBeta?: boolean;
}) => {
  return (
    <MenuItem onClick={() => handleChange(kind)} icon={icon}>
      <Box fontSize="text2" fontWeight="bold">
        {label}
        {isBeta && <BetaFeatureTag ml="2" />}
      </Box>
      <Box fontSize="memo1" color="color.hambleA11y">
        {description}
      </Box>
    </MenuItem>
  );
};

const useHandleTourProgress = () => {
  const {progress: progressIwCreateProject} = useTour({
    tour: GettingStartedTour.IwCreateProject,
    step: 'selectProjectTypeIndexWorker',
  });
  const {progress: progressSaCreateProject} = useTour({
    tour: GettingStartedTour.SaCreateProject,
    step: 'selectProjectTypeSearchAnalytics',
  });

  return (kind: ProjectKind | '') => {
    match(kind)
      .with(ProjectKind.IndexWorker, () => progressIwCreateProject())
      .with(ProjectKind.SearchAnalytics, () => progressSaCreateProject())
      .with(ProjectKind.UserAnalytics, () => {}) // TODO: Tour for UserAnalytics
      .with('', () => {})
      .exhaustive();
  };
};

const TourTooltipWrapper = ({children}: {children: React.ReactNode}) => {
  const runningTour = useRunningTour();
  if (
    runningTour !== GettingStartedTour.IwCreateProject &&
    runningTour !== GettingStartedTour.SaCreateProject
  )
    return <>{children}</>;

  return (
    <TourTooltip
      tour={runningTour}
      step={
        runningTour === GettingStartedTour.IwCreateProject
          ? 'selectProjectTypeIndexWorker'
          : 'selectProjectTypeSearchAnalytics'
      }
      placement="end"
    >
      {children}
    </TourTooltip>
  );
};
