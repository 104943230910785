import {Image, chakra, Tag, HStack, Box} from '@chakra-ui/react';
import {useOrg} from 'common/useOrg';
import {pagesPath, staticPath} from 'lib/$path';
import {useRouter} from 'next/router';
import Link from 'next/link';
import {Env} from 'env/env';

export const Logo = () => {
  const router = useRouter();
  const {defaultOrgDatabaseId, currentOrgDatabaseId} = useOrg();

  const content = (
    <HStack gap="10px" spacing={0} alignItems="center">
      <LogoImage />
      {/* if mobile, not show badge */}
      <Box
        sx={{
          '@container appBar (max-width: 600px)': {
            display: 'none',
          },
        }}
      >
        {/* Show badge 'Demo' or 'Public Beta' */}
        {Env.ENV === 'demo' ? <Tag>{'Demo'}</Tag> : null}
      </Box>
    </HStack>
  );

  if (router.pathname === pagesPath.onboarding.$url({query: {}}).pathname)
    return content;

  return (
    <Link
      href={
        !defaultOrgDatabaseId
          ? pagesPath.$url()
          : currentOrgDatabaseId
          ? pagesPath.orgs._orgId(currentOrgDatabaseId).$url()
          : pagesPath.orgs._orgId(defaultOrgDatabaseId).$url()
      }
      passHref
      legacyBehavior
    >
      <chakra.a
        title="Amethyst TOP"
        aria-label="Amethyst TOP"
        sx={{
          outlineColor: 'var(--chakra-ring-color)',
        }}
      >
        {content}
      </chakra.a>
    </Link>
  );
};

const LogoImage = () => {
  return (
    <Image
      src={staticPath.images.logo_with_text_on_right_svg}
      alt="Amethyst"
      height="40px"
    />
  );
};
