import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Portal,
} from '@chakra-ui/react';
import {LOCALE_COOKIES} from 'common/storage';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {MdLanguage} from 'react-icons/md';
import {useUpdateUserMutation, useViewerQuery} from 'lib/gql/generated';
import {notifyError} from 'common/errorTracker/errrorTracker';

type Props = {
  size?: 'sm' | 'md';
  stretch?: boolean;
};

const writeCookie = (lang: 'ja' | 'en') => {
  if (typeof document === 'undefined') return;
  document.cookie = LOCALE_COOKIES[lang];
};

export const LangSwitcher = ({size = 'md', stretch = false}: Props) => {
  const {t} = useTranslation('layout', {
    keyPrefix: 'SideNavigation',
  });

  const {data} = useViewerQuery();
  const viewrId = data?.viewer?.id;

  const router = useRouter();

  const [updateUser, {loading}] = useUpdateUserMutation();

  const handleClick = async (lang: 'ja' | 'en') => {
    writeCookie(lang);
    if (!viewrId) return;
    try {
      await updateUser({
        variables: {
          input: {
            userID: viewrId,
            preferredLanguage: lang,
          },
        },
      });
    } catch (error) {
      notifyError(
        new Error('Failed to update user language', {
          cause: error,
        })
      );
    } finally {
      router.push(router.asPath, undefined, {locale: lang});
    }
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        textAlign="left"
        leftIcon={<MdLanguage size="18px" />}
        size={size}
        flex={stretch ? 1 : undefined}
      >
        {t('language')}
      </MenuButton>
      {/* 言語選択部分はどの言語でも同じように表示する */}
      {/* eslint-disable i18next/no-literal-string */}
      <Portal>
        <MenuList zIndex="overlay">
          <MenuItem
            isDisabled={loading}
            fontWeight={router.locale === 'ja' ? 'bold' : 'normal'}
            onClick={() => {
              handleClick('ja');
            }}
          >
            日本語
          </MenuItem>
          <MenuItem
            isDisabled={loading}
            fontWeight={router.locale === 'en' ? 'bold' : 'normal'}
            onClick={() => {
              handleClick('en');
            }}
          >
            English
          </MenuItem>
        </MenuList>
      </Portal>
      {/* eslint-enable i18next/no-literal-string */}
    </Menu>
  );
};
